/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./client-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./client-list.component";
import * as i5 from "../../base-api.service";
import * as i6 from "ngx-spinner";
import * as i7 from "@angular/common/http";
var styles_ClientListComponent = ["[_nghost-%COMP%]  multiselect-dropdown[_ngcontent-c5] .dropdown-btn[_ngcontent-c5] {\n  border:0px;\n}\n\n\n[_nghost-%COMP%] .dropdown-btn { border-radius: 6px !important;  border: 0px solid #999999 !important;\npadding: 0px 0px !important;\n\n }", i0.styles];
var RenderType_ClientListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientListComponent, data: {} });
export { RenderType_ClientListComponent as RenderType_ClientListComponent };
function View_ClientListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["style", "width:100px;word-wrap: break-word;"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["style", "width:100px;word-wrap: break-word;"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["style", "width:100px;word-wrap: break-word;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 6, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 5, "div", [["style", "width:100px;word-wrap: break-word;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 4, "a", [["fragment", "api"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], fragment: [1, "fragment"], routerLink: [2, "routerLink"] }, null), i1.ɵpod(17, { clientId: 0 }), i1.ɵpad(18, 1), (_l()(), i1.ɵted(-1, null, [" Add/View Api "]))], function (_ck, _v) { var currVal_5 = _ck(_v, 17, 0, _v.context.$implicit.clientId); var currVal_6 = "api"; var currVal_7 = _ck(_v, 18, 0, "/vehicle"); _ck(_v, 16, 0, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_0 = (_v.context.index + 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.clientId; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.clientName; _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 16).target; var currVal_4 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_3, currVal_4); }); }
function View_ClientListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "mat-elevation-z8 table-responsive"], ["style", "overflow-y: scroll; height:400px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "table", [["class", "table table-bordered"], ["style", "overflow-y: scroll; height:400px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 14, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 13, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sr No"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Client Id"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Start Date"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["End Date"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["API List"])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientListComponent_2)), i1.ɵdid(19, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.event_array; _ck(_v, 19, 0, currVal_0); }, null); }
export function View_ClientListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "lyt-main"], ["id", "lyt-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 25, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 24, "div", [["class", "cp-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "sec-head full-width-lw"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "left-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "sec-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Clients List "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "right-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 14, "div", [["class", "sec-head full-width-lw"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 13, "div", [["class", "right-wrap "]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 12, "ul", [["class", "act-list typ-multiline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "li", [["class", "act-item typ-full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "cp-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "li", [["class", "act-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-icon-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fetchReport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "span", [["class", "icon icon-search"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Search"])), (_l()(), i1.ɵeld(19, 0, null, null, 3, "li", [["class", "act-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "button", [["class", "btn btn-icon-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addClient() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "span", [["class", "icon icon-add"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add"])), (_l()(), i1.ɵeld(23, 0, null, null, 3, "div", [["class", "sec-cont"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 2, "div", [["class", "cp-card typ-table"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientListComponent_1)), i1.ɵdid(26, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resultCheck; _ck(_v, 26, 0, currVal_0); }, null); }
export function View_ClientListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-client-list", [], null, null, null, View_ClientListComponent_0, RenderType_ClientListComponent)), i1.ɵdid(1, 114688, null, 0, i4.ClientListComponent, [i2.Router, i5.BaseApiService, i6.NgxSpinnerService, i7.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientListComponentNgFactory = i1.ɵccf("app-client-list", i4.ClientListComponent, View_ClientListComponent_Host_0, {}, {}, []);
export { ClientListComponentNgFactory as ClientListComponentNgFactory };
