/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./query.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "./query.component";
var styles_QueryComponent = [i0.styles];
var RenderType_QueryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QueryComponent, data: {} });
export { RenderType_QueryComponent as RenderType_QueryComponent };
function View_QueryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "act-item typ-full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "cp-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(4, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.templateRef; _ck(_v, 4, 0, currVal_0); }, null); }
function View_QueryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-icon-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit("download") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "icon icon-download"]], null, null, null, null, null))], null, null); }
function View_QueryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-icon-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit("refresh") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["refresh"]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_QueryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "disclaimer"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disclaimer; _ck(_v, 1, 0, currVal_0); }); }
export function View_QueryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "sec-head full-width-lw "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "left-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "sec-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 17, "div", [["class", "sec-head full-width-lw"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 16, "div", [["class", "right-wrap "]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 15, "ul", [["class", "row typ-multiline"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryComponent_1)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "search-download-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "li", [["class", "act-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-icon-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit("query") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "span", [["class", "icon icon-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "li", [["class", "act-button"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryComponent_2)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "li", [["class", "act-button"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryComponent_3)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryComponent_4)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.inputGroups; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.allowDownload; _ck(_v, 15, 0, currVal_2); var currVal_3 = _co.allowRefresh; _ck(_v, 18, 0, currVal_3); var currVal_4 = _co.disclaimer; _ck(_v, 21, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_QueryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-query", [], null, null, null, View_QueryComponent_0, RenderType_QueryComponent)), i1.ɵdid(1, 638976, null, 1, i5.QueryComponent, [], null, null), i1.ɵqud(603979776, 1, { inputGroups: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QueryComponentNgFactory = i1.ɵccf("app-query", i5.QueryComponent, View_QueryComponent_Host_0, { title: "title", allowDownload: "allowDownload", allowRefresh: "allowRefresh", disclaimer: "disclaimer" }, { query: "query", download: "download", refresh: "refresh" }, []);
export { QueryComponentNgFactory as QueryComponentNgFactory };
