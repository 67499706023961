import { EventEmitter } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class ConfigService {
    constructor() {
        this.userInfoChangeEvent = new EventEmitter();
        this.isAllVerified = false;
        this.branchID = new BehaviorSubject('');
        this.branchChange = new BehaviorSubject('');
        this.moduleNameChange = new BehaviorSubject('');
    }
    getKeyCloakToken() {
        return 'Bearer ' + localStorage.getItem('auth_token');
    }
    getApplicationHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-type': 'application/json',
            })
        };
    }
    getAddEditHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-type': 'application/json',
                Authorization: this.getKeyCloakToken()
            })
        };
    }
    setLoggedInUserInfo(userInfo) {
        localStorage.setItem('loggedInUserInfo', JSON.stringify(userInfo));
    }
    getLoggedInUserInfo() {
        return this.loggedInUserInfo = JSON.parse(localStorage.getItem('loggedInUserInfo'));
    }
    getBytes(bytes) {
        const decimals = 2;
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(); }, token: ConfigService, providedIn: "root" });
