import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { appLevelConstants } from '../shared/configurations/app-level-constants';
import { redirectUrlConstants, urlConstants } from '../shared/configurations/url-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "ngx-toastr";
import * as i3 from "../login.service";
import * as i4 from "./firebase.service";
export class AccessInterceptor {
    constructor(router, toaster, loginService, firebaseService) {
        this.router = router;
        this.toaster = toaster;
        this.loginService = loginService;
        this.firebaseService = firebaseService;
        webGlObject.init();
    }
    intercept(req, next) {
        if (req.url.includes(urlConstants.LOGOUT_API)) {
            return next.handle(req);
        }
        else {
            return next.handle(req).pipe(tap({
                next: (response) => {
                    if (response instanceof HttpResponse) {
                        if (response.body.status === 403) {
                            let req = {
                                refresh_token: localStorage.getItem('refresh_token'),
                                role_type: localStorage.getItem('role_type'),
                                user_id: localStorage.getItem('user_id'),
                                action: appLevelConstants.LOGOUT_ACTION,
                                module_name: appLevelConstants.LOGOUT_MODULE_NAME
                            };
                            this.firebaseService.createEvent('User Logout', {
                                user_name: localStorage.getItem('user_id'),
                                user_role: localStorage.getItem('user_id')
                            });
                            this.loginService.userLogout(req).subscribe((response) => {
                                return;
                            });
                            localStorage.clear();
                            Cookie.deleteAll();
                            this.router.navigateByUrl(redirectUrlConstants.REDIRECT_LOGIN);
                        }
                    }
                    return;
                },
                error: (error) => {
                    console.log("error", error);
                }
            }));
        }
    }
}
AccessInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccessInterceptor_Factory() { return new AccessInterceptor(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.LoginService), i0.ɵɵinject(i4.FirebaseService)); }, token: AccessInterceptor, providedIn: "root" });
