import { HttpClient } from '@angular/common/http';
import { urlConstants } from 'src/app/shared/configurations/url-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../config.service";
export class PushNotificationService {
    constructor(http, configService) {
        this.http = http;
        this.configService = configService;
        this.addhttpOptions = this.configService.getAddEditHeaders();
    }
    userPushNotification(userData) {
        return this.http.post(urlConstants.API_PUSH_NOTIFICATION, JSON.stringify(userData), this.addhttpOptions);
    }
}
PushNotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PushNotificationService_Factory() { return new PushNotificationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: PushNotificationService, providedIn: "root" });
