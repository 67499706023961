import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { appLevelConstants } from '../configurations/app-level-constants';
import { ConfigService } from 'src/app/config.service';
import { redirectUrlConstants } from '../configurations/url-constants';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login.service';
import { ConfirmationService } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { UserActivityService } from 'src/app/services/user-activity.service';
import { UserIdleService } from 'angular-user-idle';
import { UserProfileComponent } from 'src/app/Components/user-profile/user-profile.component'
import { FirebaseService } from 'src/app/services/firebase.service';
import { USER_PLACEHODER , BANNER_LOGO ,TATA_LOGO_BLACK } from '@shared/configurations/image-constants';

declare var myExtObject: any;
declare var webGlObject: any;
@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})

export class MainHeaderComponent {
  USER_PLACEHODER = USER_PLACEHODER;
  BANNER_LOGO = BANNER_LOGO;
  TATA_LOGO_BLACK =TATA_LOGO_BLACK;
  reqObj2: any = {
    from_date: "",
    page_number: 1,
    to_date: "",
    fleetowner_email: "",
  };

  isSessionExpired: boolean = false;
  vehicleListarray: [];
  response: any;
  user_id: any;
  type: any;
  userInfo = this.configService.getLoggedInUserInfo();
  isUserRoleAdmin: boolean = false;
  adminCount: any;
  tmlInternalCount: any;
  supportCount: any;
  showPopUp: boolean;
  public toaster: ToastrService;
  reqObj: any;
  dse_name = '';
  loggedInnUser = true;
  submitted = false;
  showTableData = false;
  showLoading = false;
  isAccolade = false;
  isElectra = false;
  isTMLInternal = false;
  public showMoreLink = false;
  public fleetMasterLinks = false;
  public notifiyDD = false;
  public userProfileDD = false;
  public sosNotifyDD = false;
  public showOverlay = false;
  public show = false;
  public isdisabled = false;
  totalRecords: any;
  hoverIcon = false;
  isSupportDeskUser = true;
  // labels.
  userOnboardLabel = appLevelConstants.USER_ONBOARD_LABEL;
  eventDataLabel = appLevelConstants.EVENT_DATA_LABEL;
  telemetryDataLabel = appLevelConstants.TELEMETRY_DATA_LABEL;
  vehicleCanDataLabel = appLevelConstants.VEHICLE_CAN_DATA_LABEL;
  fuelAndOdometerDataLabel = appLevelConstants.FUEL_AND_ODOMETER_DATA_LABEL;
  ignitionStatusLabel = appLevelConstants.IGNITION_STATUS_LABEL;
  vehicleListLable = appLevelConstants.VEHICLE_LIST_LABEL;
  fuelAlertLabel = appLevelConstants.FUEL_ALERT_LABEL;
  vehicleOnBoardLabel = appLevelConstants.VEHICLE_ON_BOARD_LABEL;

  constructor(private breakpointObserver: BreakpointObserver,
    private confirmationService: ConfirmationService,
    private userActivityService: UserActivityService,
    public loginService: LoginService,
    private configService: ConfigService,
    private router: Router,
    private userIdle: UserIdleService,
    private firebaseService: FirebaseService,
    
    ) {
    const dse_name = localStorage.getItem('username');
    this.dse_name = dse_name;
  }

  get ro() { return this.response; }
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset,
  Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  yes = appLevelConstants.YES;
  no = appLevelConstants.NO;
  showPopup = false;

  ngOnInit() {
    this.hoverIcon = true;
    // ADMIN CHECK
    if (this.userInfo.role_type === appLevelConstants.ROLES[0]) {
      this.isUserRoleAdmin = true;
    }
    else{
      this.isUserRoleAdmin = false;
    }

    // TML INTERNAL CHECK
    if (this.userInfo.role_type === appLevelConstants.ROLES[1]) {
      this.isTMLInternal = true;
    }
    else{
      this.isTMLInternal = false;
    }

    // SUPPORT DESK CHECK
    if (this.userInfo.role_type === appLevelConstants.ROLES[2]) {
      this.isSupportDeskUser = true;
    }
    else{
      this.isSupportDeskUser = false;
    }

    // ACCOLADE CEHCK
    if (this.userInfo.role_type === appLevelConstants.ROLES[3]) {
      this.isAccolade = true;
    }
    else {
      this.isAccolade = false;
    }

    // ELECTRA USER CHECK
    if (this.userInfo.role_type === appLevelConstants.ROLES[4]) {
      this.isElectra = true;
    }
    else {
      this.isElectra = false;
    }


    const token = localStorage.getItem('auth_token');
    if (token !== undefined && token !== '') {
      this.loggedInnUser = true;
    }

    //Start watching for user inactivity.
    this.userIdle.startWatching();

    // Start watching when user idle is starting and reset if user action is there.
    this.userIdle.onTimerStart().subscribe(count => {
      var eventList = ["click", "mouseover", "keydown", "DOMMouseScroll", "mousewheel",
        "mousedown", "touchstart", "touchmove", "scroll", "keyup"];
      for (let event of eventList) {
        document.body.addEventListener(event, () => this.userIdle.resetTimer());
      }
    });

    this.userIdle.onTimeout().subscribe(() => {
      if (window.location.href.indexOf("login") > 0) {
        console.log(this.router.url)
      }
      else {
        this.isSessionExpired = true;
      }
    });
  }

  showDialog() {
    this.showPopup = true;
  }

  confirm() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to Log out?',
      accept: () => {
        this.logOut();
      }
    });
  }
  isLoggedInUser() {
    this.loggedInnUser = true;
  }

  logOut() {
    this.reqObj = {
      refresh_token: localStorage.getItem('refresh_token'),
      role_type: localStorage.getItem('role_type'),
      user_id: localStorage.getItem('user_id'),
      action: appLevelConstants.LOGOUT_ACTION,
      module_name: appLevelConstants.LOGOUT_MODULE_NAME
    };
    this.loginService.userLogout(this.reqObj).subscribe(
      (response: any) => {
        this.firebaseService.createEvent('User Logout', { 
          user_name: localStorage.getItem('user_id'),
          user_role:  localStorage.getItem('user_id')
        });
        if (response.status === 0) {
          this.toaster.success(response.message, '', {
            timeOut: 5000
          });
        } else {
          this.toaster.error(response.message, '', {
            timeOut: 5000
          });
        }
      }
    );
    this.loggedInnUser = false;
    localStorage.clear();
    this.isSessionExpired = false
    this.router.navigateByUrl(redirectUrlConstants.REDIRECT_LOGIN);
  }

  openLogoutPopup(): void {
    this.showPopUp = true;
  }

  fetchIUserActivityReport() {
    this.vehicleListarray = [];
    this.submitted = true;
    this.hoverIcon = true;
    this.reqObj2 = {
      user_id: "sanket.p1@sankeysolutions.com",
      from_date: "2021-04-02T12:0:00Z",
      to_date: "2021-05-05T20:0:00Z",
      page_number: 1,
      action: "Get event logs",
      module_name: "User Event Logs"
    };
    this.userActivityService.getUserActivity(this.reqObj2).subscribe(
      (response: any) => {
        this.showLoading = false;
        if (response.status === 0 && response.hasOwnProperty("result") === true) {
          if (response.result.length <= 0) {
            this.showTableData = false;
            this.toaster.error("No record found", '', {
              timeOut: 5000
            });
          } else {
            this.vehicleListarray = response.result;
            this.totalRecords = response.total_count;
            this.showTableData = true;
            this.type = response.result.
              this.toaster.success(response.message, '', {
                timeOut: 5000
              });
          }
        } else {
          this.showTableData = false;
          this.toaster.error(response.message, '', {
            timeOut: 5000
          });
        }
      }, (error) => {
        this.toaster.error(error.message, '', {
          timeOut: 5000
        });
      }
    );
  }
}



