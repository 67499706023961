<div class="typ-cards">
<div class="lyt-cards">
  <div class="cards-container">
    <div class="title-wrap sec-head typ-prev set-margin">
      <h2 class="sec-title title-css label-css">{{ title }}</h2>
      
      <span *ngIf="isUserRoleAdmin" class="no-margin role-type-label"><button [routerLink]="['/user-access-control']">User Acess Control</button></span>   </div>
    </div>
    <div class="cp-cards">
      <div class="cp-card-listing">
        <ul class="card-list row col-3">
          <li class="col-item card-item mg">
            <div class="cp-card cp-user shadow-card">
              <div class="card-head">
                <div class="mod-name-value typ-img">
                  <div class="img-wrap user-pic">
                    <img class="user-profile" [src]="USER_PLACEHODER" />
                  </div>
                  <h3 class="card-title">{{ userInfo.full_name }}</h3>
                  <span class="role-type-label">{{ userInfo.role_type }}</span>
                </div>
              </div>
              <div class="card-body size">
                <!-- <div class="user-desg-list">
                  <span class="desg-tag">{{ userInfo.role_type }}</span>
                </div> -->
                <div class="employee-id">
                  <span class="desg-directory">Login ID</span>
                  <span class="desg-id">{{ loginId }}</span>
              </div>
              </div>
            </div>
          </li>


          <li class="col-item card-item mg">
            <div class="cp-card-listing item-2">
              <div class="cp-card cp-user shadow-card">
                <div class="card-head">
                  <h3 class="card-title">{{ contactDetailsTitle }}</h3>
                </div>
                <div class="card-body">
                  <div class="contact-details">
                    <ul class="data-list">
                      <li class="data-item">
                        <span class="text">{{ mobileNoLabel }}</span>
                        <span class="value">+91 {{ userInfo.phone_number }}</span>
                      </li>
                      <li class="data-item">
                        <span class="text">{{ emailIdLabel }}</span>
                        <span class="value">{{ emailId }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li> 
          

          <li class="col-item card-item mg" *ngIf="isUserRoleAdmin">
            <div class="cp-card-listing item-3">
              <div class="cp-card cp-user shadow-card">
                <div class="card-head">
                  <h3 class="card-title">{{ activeUsersTitle }}</h3>
                </div>
                <div class="card-body">
                  <div class="contact-details">
                    <ul class="data-list">
                      <li class="data-item">
                        <span class="text">{{ adminLabel }}</span>
                        <span class="value-alt">{{ activeAdminCount }}</span>
                      </li>
                      <li class="data-item">
                        <span class="text">{{ tmlInternalLabel }}</span>
                        <span class="value-alt">{{ activeTmlInternalCount }}</span>
                      </li>
                      <li class="data-item">
                        <span class="text">{{ supportDeskLabel }}</span>
                        <span class="value-alt">{{ activeSupportDeskCount }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="col-item card-item mg" *ngIf="isUserRoleAdmin">
            <div class="cp-card-listing item-3">
              <div class="cp-card cp-user shadow-card">
                <div class="card-head">
                  <h3 class="card-title">{{ inActiveUsersTitle }}</h3>
                </div>
                <div class="card-body">
                  <div class="contact-details">
                    <ul class="data-list">
                      <li class="data-item">
                        <span class="text">{{ adminLabel }}</span>
                        <span class="value-alt">{{ inActiveAdminCount }}</span>
                      </li>
                      <li class="data-item">
                        <span class="text">{{ tmlInternalLabel }}</span>
                        <span class="value-alt">{{ inActiveTmlInternalCount }}</span>
                      </li>
                      <li class="data-item">
                        <span class="text">{{ supportDeskLabel }}</span>
                        <span class="value-alt">{{ inActiveSupportDeskCount }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </div>