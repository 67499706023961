<ng-container *ngIf="tableData && tableData.length>0">
<!-- passing empty tabledata array might cause pagination issues -->
    <div class="sec-cont tb">
        
        <div class="cp-card typ-table">
            <div class="mat-elevation-z4 table-responsive">
                <table class="table table-bordered" id="excel-table" >

                    <thead >
                        <tr>
                            <th *ngFor="let col of columnData" class="text-cap"> {{col.displayName }} </th>
                            <!-- <th *ngIf="viewLocation">View Location On Map</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let rowData of tableData;let i= index">
                          
                            <ng-container *ngFor="let col of columnData">
                                <td *ngIf="(rowData[col.dataKey]!==null && rowData[col.dataKey]!==undefined ) && !col.custom" >
                                    
                                    {{ ( col.transform!==undefined && col.transform!==null)?    col.transform(rowData[col.dataKey]) : rowData[col.dataKey] }}
                                </td>
                                <td *ngIf="(rowData[col.dataKey]===null || rowData[col.dataKey]===undefined) && !col.custom" >
                                    
                                    {{( col.alternative!==undefined && col.alternative!==null)?  col.alternative : "-"}}
                                </td>
                                
                                
                                    <td *ngIf="col.custom && customCellTemplates[col.dataKey]" class="custom-cell">
                                        <ng-container  [ngTemplateOutlet]="customCellTemplates[col.dataKey]"
                                        [ngTemplateOutletContext]="{$implicit:rowData}">
                                
                                    </ng-container>
                                    </td>
                                  
                            </ng-container>
                            
                            <!-- <td *ngIf="viewLocation"><p style="cursor: pointer;"  (click)="viewLocationEvent()">Click</p></td> -->
                        
                            <ng-template #noData>
                                <td>- </td>
                            </ng-template>
                        </tr>
                        
                    </tbody>
                    
                </table>
                <!-- <div *ngIf="watermarkEnabled" class="watermark">{{ this.waterMarkLabel }}</div> -->
            </div>
        </div>
    </div>

    <div class="pagination" *ngIf="paginationCounterVisbibe">
        <app-table-pagination *ngIf="totalRecords" (paginationEvent)="onPaginationEvent($event)"
            [totalRecords]='totalRecords' [currentPage]="currentPage">
        </app-table-pagination>
    </div>


</ng-container>