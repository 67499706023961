import * as i0 from "@angular/core";
export class LoaderService {
    constructor() {
        this.showLoader = false;
    }
    show() {
        this.showLoader = true;
    }
    hide() {
        this.showLoader = false;
    }
}
LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
