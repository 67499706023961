import { HttpHeaders } from '@angular/common/http';
import { urlConstants } from 'src/app/shared/configurations/url-constants';
import { appLevelConstants } from '@shared/configurations/app-level-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../config.service";
export class VehicleListService {
    constructor(http, configService) {
        this.http = http;
        this.configService = configService;
        this.httpOptions = this.configService.getApplicationHeaders();
        this.addhttpOptions = this.configService.getAddEditHeaders();
    }
    // API call for fetching vehicle list
    getVehicleData(userData) {
        return this.http.post(urlConstants.VEHICLE_LIST_API, JSON.stringify(userData), this.getAddEditHeaders());
    }
    getLocationName(userData) {
        return this.http.post(urlConstants.API_GET_PLACES_ON_MAP, JSON.stringify(userData), this.getAddEditHeaders());
    }
    getAddressStateData() {
        return this.http.post(appLevelConstants.GET_STATE_URL, {}, this.configService.getApplicationHeaders());
    }
    getAddressDistrictByState(state) {
        return this.http.post(appLevelConstants.GET_DISTRICT_URL, state, this.configService.getApplicationHeaders());
    }
    getAddressCitiesByStateDistrict(district) {
        return this.http.post(appLevelConstants.GET_CITY_URL, district, this.configService.getApplicationHeaders());
    }
    getKeyCloakToken() {
        return 'Bearer ' + localStorage.getItem('auth_token');
    }
    getAddEditHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-type': 'application/json',
                Authorization: this.getKeyCloakToken()
            })
        };
    }
}
VehicleListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VehicleListService_Factory() { return new VehicleListService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: VehicleListService, providedIn: "root" });
