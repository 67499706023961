<div class="lyt-main" id="lyt-main">
    <div class="cp-section">
        <form action="" [formGroup]="addVehicleOnBoard">

        <div class="sec-head full-width-lw ">
            <div class="left-wrap">
                <h2 class="sec-title">Vehicle On-Board</h2>
            </div>
            <div class="right-wrap">
                <div class="item" (click)="bulkUpload()">
                    <button class="btn btn-icon-text">
                        <span class="icon"> <img [src]="BULK_UPLOAD_SVG" alt=""
                                class=" offboard-icon">
                        </span>
                        <span class="text">Import</span></button>
                </div>
            </div>
        </div>
        <form action="" [formGroup]="checkVehicleOnBoard">
        <div class="sec-head full-width-lw">
            <div class="right-wrap ">
                <ul class="row typ-multiline">
                    <li class="act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                    <label class="form-label">{{fleetId}}</label>
                                    <div class="input-group ps">
                                        <p-dropdown [options]="dropdownEvents" placeholder="Select Fleet Id" formControlName="fleetId" [virtualScroll]="true" 
                                        class="form-control dropdownfix" [ngClass]="(submitted || fc.fleetId.dirty) && fc.fleetId.errors ? 'is-invalid':''">
                                        </p-dropdown>
                                    </div>  
                            </div>
                        </div>    
                    </li>  
                    
                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{vinNumber}}</label>
                                <div class="input-group">
                                    <input type="tel" class="form-control" placeholder="Vin Number" minlength="14"
                                      maxlength="17" formControlName="vinNumber"
                                     [ngClass]="(submitted || fc.vinNumber.dirty) && fc.vinNumber.errors ? 'is-invalid':''">
                                     <div *ngIf="(fc.vinNumber.dirty || submitted) && fc.vinNumber.errors" 
                                            class="invalid-error">
                                        <div *ngIf="fc.vinNumber.errors.required">
                                            {{errorMessages.FIELD_IS_REQUIRED}}
                                        </div>
                                        <div *ngIf=" fc.vinNumber.errors.pattern ">
                                            {{errorMessages.VIN_IS_INVALID}}
                                        </div>
                                    </div>    
                                </div>   
                            </div>
                        </div>
                    </li>
                    
                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <button type="submit" class="btn btn-default add-btn mt-5" (click)="CheckVinForFleetId();">
                                    {{check}}
                                </button>
                            </div>
                        </div>
                    </li>

                </ul>
            </div>        
        </div>
    </form>

    <div class="section" *ngIf="visible">
        <div class="sec-head full-width-lw">
            <div class="right-wrap ">
                <ul class="row typ-multiline">

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{branchId}}</label>
                                <div class="input-group">
                                    <p-dropdown formControlName="branch_id" placeholder="Choose" [options]="branchData" optionLabel="branch_name" class="form-control dropdownfix"
                                    [ngClass]="(submitted || getformControls.branch_id.dirty) && getformControls.branch_id.errors ? 'is-invalid':''">
                                    </p-dropdown>
                                    <div *ngIf="(getformControls.branch_id.dirty || submitted) && getformControls.branch_id.errors" 
                                            class="invalid-error">
                                        <div *ngIf="getformControls.branch_id.errors.required">
                                            {{errorMessages.FIELD_IS_REQUIRED}}
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{regNumber}}</label>
                                <div class="input-group">
                                    <input type="text" formControlName="regNumber" pInputText class="form-control" placeholder="Enter text here" minlength="8"
                                    [ngClass]="(submitted || getformControls.regNumber.dirty) && getformControls.regNumber.errors ? 'is-invalid':''">
                                    <div *ngIf="(getformControls.regNumber.dirty || submitted) && getformControls.regNumber.errors" 
                                            class="invalid-error">
                                        <div *ngIf="getformControls.regNumber.errors.required">
                                            {{errorMessages.FIELD_IS_REQUIRED}}
                                        </div>
                                        <div *ngIf=" getformControls.regNumber.errors.pattern">
                                            {{errorMessages.REG_IS_INVALID}}
                                        </div>
                                    </div> 
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{regDate}}</label>
                                <div class="input-group">
                                    <p-calendar formControlName="regDate" placeholder="dd/mm/yy" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:3000" class="dropdownfix" [maxDate]="currentDate">
                                    </p-calendar>
                                </div>   
                            </div>
                        </div>
                    </li>

                </ul>
            </div>        
        </div>

        <div class="sec-head full-width-lw">
            <div class="right-wrap ">
                <ul class="row typ-multiline">



                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{make}}</label>
                                <div class="input-group">
                                    <input type="text" formControlName="make" placeholder="Enter text here" pInputText class="form-control"
                                    [ngClass]="(submitted || getformControls.make.dirty) && getformControls.make.errors ? 'is-invalid':''">
                                    <div *ngIf="(getformControls.make.dirty || submitted) && getformControls.make.errors" 
                                            class="invalid-error">
                                        <div *ngIf=" getformControls.make.errors.pattern ">
                                            {{errorMessages.MAKE_IS_INVALID}}
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{vehicleModel}}</label>
                                <div class="input-group">
                                    <input type="text" formControlName="vehicleModel" pInputText class="form-control" placeholder="Enter text here">
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{emitionNom}}</label>
                                <div class="input-group">
                                      <p-dropdown formControlName="emitionNom" placeholder="Choose" [options]="emitionNomData" 
                                       optionLabel="emition_nome" class="form-control dropdownfix">
                                    </p-dropdown>
                                </div>   
                            </div>
                        </div>
                    </li>

                </ul>
            </div>        
        </div> 
        
        <div class="sec-head full-width-lw">
            <div class="right-wrap ">
                <ul class="row typ-multiline">   
                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{engineNumber}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Engine Number" formControlName="engineNumber" 
                                    [ngClass]="(submitted || getformControls.engineNumber.dirty) && getformControls.engineNumber.errors ? 'is-invalid':''">
                                      <div *ngIf="(getformControls.engineNumber.dirty || submitted) && getformControls.engineNumber.errors" 
                                            class="invalid-error">
                                        <div *ngIf=" getformControls.engineNumber.errors.pattern ">
                                            {{errorMessages.ENGNO_IS_INVALID}}
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{fuelType}}</label>
                                <div class="input-group">
                                    <p-dropdown formControlName="fuelType" placeholder="Choose" [options]="fuelTypeData" 
                                       optionLabel="fuel_type" class="form-control dropdownfix" 
                                       [ngClass]="(submitted || getformControls.fuelType.dirty) && getformControls.fuelType.errors ? 'is-invalid':''">
                                       <div *ngIf="(getformControls.fuelType.dirty || submitted) && getformControls.fuelType.errors" 
                                            class="invalid-error">
                                            <div *ngIf="getformControls.fuelType.errors.required">
                                                {{errorMessages.FIELD_IS_REQUIRED}}
                                            </div>
                                        </div>
                                    </p-dropdown>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{vehicleApplication}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here" formControlName="vehicleApplication" 
                                    [ngClass]="(submitted || getformControls.vehicleApplication.dirty) && getformControls.vehicleApplication.errors ? 'is-invalid':''">
                                      <div *ngIf="(getformControls.vehicleApplication.dirty || submitted) && getformControls.vehicleApplication.errors" 
                                            class="invalid-error">
                                        <div *ngIf=" getformControls.vehicleApplication.errors.pattern ">
                                            {{errorMessages.VA_IS_INVALID}}
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </li> 
                </ul>
            </div>        
        </div>

        <div class="sec-head full-width-lw">
            <div class="right-wrap ">
                <ul class="row typ-multiline"> 
                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{vehicleTypee}}</label>
                                <div class="input-group">
                                    <p-dropdown formControlName="vehicleType" placeholder="Choose" [options]="vehicleTypeData" 
                                       optionLabel="vehicle_type" class="form-control dropdownfix">
                                    </p-dropdown>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{communicationType}}</label>
                                <div class="input-group">
                                    <p-dropdown formControlName="communicationType" placeholder="Choose" [options]="communicationData" 
                                    optionLabel="comm_type" class="form-control dropdownfix">
                                    </p-dropdown>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{vehicleLob}}</label>
                                <div class="input-group">
                                    <p-dropdown [options]="lobData" placeholder="Select Lob" formControlName="vehicleLob"
                                        [ngClass]="(submitted || getformControls.vehicleLob.dirty) && getformControls.vehicleLob.errors ? 'is-invalid':''"
                                        [virtualScroll]="true" class="form-control dropdownfix">
                                    </p-dropdown>
                                </div>   
                            </div>
                        </div>
                    </li>
                </ul>
            </div>        
        </div>

        <div class="sec-head full-width-lw">
            <div class="right-wrap ">
                <ul class="row typ-multiline">
                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{accountName}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here" formControlName="account_name" 
                                      [ngClass]="(submitted || getformControls.account_name.dirty) && getformControls.account_name.errors ? 'is-invalid':''">
                                      <div *ngIf="(getformControls.account_name.dirty || submitted) && getformControls.account_name.errors" 
                                            class="invalid-error">
                                        <div *ngIf=" getformControls.account_name.errors.pattern ">
                                            {{errorMessages.AN_IS_INVALID}}
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{bodyType}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here"
                                      formControlName="bodyType">
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{tonnage}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here" formControlName="tonnage"
                                       [ngClass]="(submitted || getformControls.tonnage.dirty) && getformControls.tonnage.errors ? 'is-invalid':''">
                                      <div *ngIf="(getformControls.tonnage.dirty || submitted) && getformControls.tonnage.errors" 
                                            class="invalid-error">
                                        <div *ngIf=" getformControls.tonnage.errors.pattern ">
                                            {{errorMessages.TONNAGE_IS_INVALID}}
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </li>
                </ul>
            </div>        
        </div>

        <div class="sec-head full-width-lw">
            <div class="right-wrap ">
                <ul class="row typ-multiline">

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{makeYearMonth}}</label>
                                <div class="input-group">
                                    <p-calendar formControlName="makeYearMonth" placeholder="dd/mm/yy" dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true" yearRange="1900:3000" class="dropdownfix">
                                    </p-calendar>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{regAddress}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here"
                                      formControlName="regAddress">
                                </div>   
                            </div>
                        </div>
                    </li>
                
                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{corridor}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here" formControlName="corridorName" 
                                      [ngClass]="(submitted || getformControls.corridorName.dirty) && getformControls.corridorName.errors ? 'is-invalid':''">
                                      <div *ngIf="(getformControls.corridorName.dirty || submitted) && getformControls.corridorName.errors" 
                                            class="invalid-error">
                                        <div *ngIf=" getformControls.corridorName.errors.pattern ">
                                            {{errorMessages.CN_IS_INVALID}}
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </li>
                </ul>
            </div>        
        </div>

        <div class="sec-head full-width-lw">
            <div class="right-wrap ">
                <ul class="row typ-multiline">
                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{state}}</label>
                                <div class="input-group">
                                    <p-dropdown formControlName="state" placeholder="Choose" [options]="stateDropdown" (onChange)="getAddressDistricts()" dataKey="name" optionLabel="name" class="form-control dropdownfix">
                                    </p-dropdown>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{district}}</label>
                                <div class="input-group">
                                    <p-dropdown formControlName="district" placeholder="Choose" [options]="districtDropdown" (onChange)="getAddressCities()" class="form-control dropdownfix">
                                    </p-dropdown>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{city}}</label>
                                <div class="input-group">
                                    <p-dropdown formControlName="city" placeholder="Choose" [options]="cityDropdown" (onChange)="getAddressCities()" class="form-control dropdownfix">
                                    </p-dropdown>
                                </div>   
                            </div>
                        </div>
                    </li>
                </ul>
            </div>        
        </div>


        <div class="sec-head full-width-lw">
            <div class="right-wrap ">
                <ul class="row typ-multiline">
                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{specification}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here" formControlName="specification"
                                      [ngClass]="(submitted || getformControls.specification.dirty) && getformControls.specification.errors ? 'is-invalid':''">
                                      <div *ngIf="(getformControls.specification.dirty || submitted) && getformControls.specification.errors" 
                                            class="invalid-error">
                                        <div *ngIf=" getformControls.specification.errors.pattern ">
                                            {{errorMessages.SPEC_IS_INVALID}}
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{noOfWheels}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here"
                                      formControlName="noWheels" [ngClass]="(submitted || getformControls.noWheels.dirty) && getformControls.noWheels.errors ? 'is-invalid':''">
                                      <div *ngIf="(getformControls.noWheels.dirty || submitted) && getformControls.noWheels.errors" 
                                            class="invalid-error">
                                        <div *ngIf=" getformControls.noWheels.errors.pattern ">
                                            {{errorMessages.WHEELS_IS_INVALID}}
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{ladenWeight}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here"
                                      formControlName="ladenWeight" [ngClass]="(submitted || getformControls.ladenWeight.dirty) && getformControls.ladenWeight.errors ? 'is-invalid':''">
                                      <div *ngIf="(getformControls.ladenWeight.dirty || submitted) && getformControls.ladenWeight.errors" 
                                            class="invalid-error">
                                        <div *ngIf=" getformControls.ladenWeight.errors.pattern ">
                                            {{errorMessages.LADEN_IS_INVALID}}
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </li>
                </ul>
            </div>        
        </div>


        <div class="sec-head full-width-lw">
            <div class="right-wrap ">
                <ul class="row typ-multiline">
                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{unladenWeight}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here"
                                      formControlName="unladenWeight" [ngClass]="(submitted || getformControls.unladenWeight.dirty) && getformControls.unladenWeight.errors ? 'is-invalid':''">
                                      <div *ngIf="(getformControls.unladenWeight.dirty || submitted) && getformControls.unladenWeight.errors" 
                                            class="invalid-error">
                                        <div *ngIf=" getformControls.unladenWeight.errors.pattern ">
                                            {{errorMessages.UNLADEN_IS_INVALID}}
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{vehicleCustomCode}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here"
                                      formControlName="vehicleCustomCode">
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{addOns}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here"
                                      formControlName="add_on">
                                </div>   
                            </div>
                        </div>
                    </li>

                </ul>
            </div>
        </div>

        <div class="sec-head full-width-lw">
            <div class="right-wrap ">
                <ul class="row typ-multiline">
                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{ownedOutside}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here"
                                      formControlName="ownedOutside">
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{vendorName}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here"
                                      formControlName="vendorName" [ngClass]="(submitted || getformControls.vendorName.dirty) && getformControls.vendorName.errors ? 'is-invalid':''">
                                      <div *ngIf="(getformControls.vendorName.dirty || submitted) && getformControls.vendorName.errors" 
                                            class="invalid-error">
                                        <div *ngIf=" getformControls.vendorName.errors.pattern ">
                                            {{errorMessages.VN_IS_INVALID}}
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </li>

                    <li class=" act-item typ-full">
                        <div class="cp-form">
                            <div class="form-group">
                                <label for="" class="form-label">{{vendorCompany}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Enter text here"
                                      formControlName="vendorCompany" [ngClass]="(submitted || getformControls.vendorCompany.dirty) && getformControls.vendorCompany.errors ? 'is-invalid':''">
                                      <div *ngIf="(getformControls.vendorCompany.dirty || submitted) && getformControls.vendorCompany.errors" 
                                            class="invalid-error">
                                        <div *ngIf=" getformControls.vendorCompany.errors.pattern ">
                                            {{errorMessages.VC_IS_INVALID}}
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </li>

                </ul>
            </div>
        </div>

<div class="sec-head full-width-lw">
    <div class="right-wrap ">
        <ul class="row typ-multiline">
            <li class=" act-item typ-full">
                <div class="cp-form">
                    <div class="form-group">
                        <button type="submit" class="btn btn-default add-btn mt-5" (click)="onSubmit();">
                            {{submit}}
                        </button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
</div>

        </form>
    </div>
</div>

<div class="bulk-upload-popup">
    <div class="cp-modal typ-upload width-class">
        <p-dialog [(visible)]="uploadDisplay" [transitionOptions]="'400ms'" [dismissableMask]="true" [modal]="true"
            [draggable]="false" [responsive]="true" [baseZIndex]="10000" [closable]="true">
            <p-header>
                <div class="modal-header-class">
                    <div>
                        <div>
                            <h3 class="title">Bulk Upload Vehicle</h3>
                            <button class="link-class"></button>
                        </div>
                        <div>
                            <button class="link-class">
                                <a class="download-img" href="{{templateLink}}" download>
                                    {{HTML_DATA_VARIABLES.download}}
                                </a>
                            </button>
                        </div>
                    </div>
                </div>

            </p-header>
            <div class="modal-body class-modal-body class-body popup_size">
                <div class="lyt-form">
                    <form action="">
                        <div class="cp-form upload-doc-wrap class-set-upload">
                            <div class="main-block">
                                <div class="file-upload">
                                    <div class="file-upload-status typ-iconText" *ngIf="!fileInput">
                                        <div class="bulk-icon">
                                            <p-fileupload accept=".xlsx, csv, .xls" chooselabel=" "
                                                maxfilesize="10485760" url="./upload.php"
                                                ng-reflect-url="./upload.php"
                                                ng-reflect-accept=".xlsx, csv, .xls"
                                                ng-reflect-auto="true"
                                                ng-reflect-max-file-size="10485760"
                                                ng-reflect-choose-label=" ">
                                                <div class="ui-fileupload ui-widget ng-star-inserted"
                                                    ng-reflect-ng-class="ui-fileupload ui-widget">
                                                    <div
                                                        class="ui-fileupload-buttonbar ui-widget-header ui-corner-top">
                                                        <span
                                                            class="ui-fileupload-choose ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-left"
                                                            icon="pi pi-plus" pbutton=""
                                                            ng-reflect-klass="ui-fileupload-choose"
                                                            ng-reflect-ng-class="[object Object]"
                                                            ng-reflect-label=" "
                                                            ng-reflect-icon="pi pi-plus">
                                                            <form [formGroup]="uploadFile"
                                                                #bulkUploadForm class="form-class">

                                                                <input #myInput type="file"
                                                                    (change)="uploadedFile($event)"
                                                                    placeholder="File Name"
                                                                    name="filename" title=" "
                                                                    accept=".xlsx,.xlsm, csv, .xls"
                                                                    formControlName="fileInput"
                                                                    [ngClass]="{ 'is-invalid': (submitted || f.fileInput.dirty) && f.fileInput.errors }">

                                                            </form>
                                                            <span aria-hidden="true"
                                                                class="ui-button-icon-left ui-clickable pi pi-plus">

                                                            </span>
                                                            <span class="ui-button-text ui-clickable"></span>
                                                        </span>

                                                    </div>

                                                    <div class="ui-fileupload-content ui-widget-content ui-corner-bottom class-box"
                                                        ng-reflect-ng-class="[object Object]">

                                                        <p-messages class="ng-tns-c10-1"
                                                            ng-reflect-enable-service="false">
                                                        </p-messages>

                                                    </div>
                                                </div>
                                            </p-fileupload>

                                        </div>
                                        <!-- </button> -->
                                        <div class="attach-file-class">
                                            <span class="class-attach-file">
                                                Attach File
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="file-error">
                                    <!-- <div *ngIf="(submittedexcel || f.fileInput.dirty) && f.fileInput.errors"
                                        class="invalid-error">
                                        <div *ngIf="f.fileInput.errors.required" class="text-danger">
                                            {{errormessages.FILE_IS_REQUIRED}}
                                        </div>
                                    </div> -->

                                    <div class="sec-head failed-sheet" *ngIf="isDisabled">
                                        <div class="failed-sheet-text-file" *ngIf="isFailedSheet">

                                            <div class="right-wrap download-btn class-padding">
                                                <button class="btn-icon btn download-btn class-color">
                                                    <span class="icon icon-download">
                                                    </span>
                                                </button>
                                            </div>
                                            <div class="failed-sheet-text">
                                                Failed excel sheet
                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <div class="sec-head failed-sheet" *ngIf="isDisabled">
                                    <div class="failed-sheet-text-file" *ngIf="isFailedSheet">

                                        <div class="right-wrap download-btn class-padding">
                                            <button class="btn-icon btn download-btn class-color">
                                                <span class="icon icon-download">
                                                </span>
                                            </button>
                                        </div>
                                        <div class="failed-sheet-text">
                                            Failed excel sheet
                                        </div>
                                    </div>


                                </div>
                                <div class="file-select-ui" *ngIf="!isDisabled">
                                    <div class="file-selected"
                                        *ngIf="fileUploaded && !uploadFile.controls.fileInput.errors && uploadFile.controls.fileInput.value != ''">
                                        <div class="first-class">
                                            <div class="img-class">
                                                <img [src]="GROUP_6" width="50" class="">
                                            </div>
                                            <div class="size-class">{{fileSize}}</div>
                                        </div>
                                        <div class="name-date-class">
                                            <div class="name-class" title="filename"> {{filename}}</div>
                                           
                                        </div>
                                        <div class="third-class">
                                            <button class="btn btn-icon" (click)="clearForm()">
                                                <span class="ui-button-icon-left ui-clickable pi pi-times"></span>
                                                <span class="text class-remove">
                                                    {{HTML_DATA_VARIABLES.remove}}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="failed-sheet-text" *ngIf="isCorrectSheet">
                                    {{HTML_DATA_VARIABLES.blanckSheetError}}
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="act-wrap class-submit footer-save-btn">
                        <div>
                            <button class="btn btn-outline"
                                (click)=" cancelBulkUploadForm()">Cancel</button>
                        </div>
                        <div>
                            <button class="btn btn-default add-btn upload-btn"
                                (click)="ReadAsJson()">Upload</button>
                        </div>
                    </div>
                </div>
            </div>
        </p-dialog>
    </div>
</div>