import { HttpClient, HttpHeaders } from '@angular/common/http';
import { urlConstants } from './shared/configurations/url-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
export class LoginService {
    constructor(http, configService) {
        this.http = http;
        this.configService = configService;
        this.isLoggedIn = true;
        this.onboardingStep = false;
        this.httpOptions = this.configService.getApplicationHeaders();
        this.addhttpOptions = this.configService.getAddEditHeaders();
    }
    getKeyCloakToken() {
        return 'Bearer ' + localStorage.getItem('auth_token');
    }
    getApplicationHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-type': 'application/json',
                Authorization: 'Basic Y3ZwLXVzZXItMTpwbHV0b2lzbm90YXBsYW5ldA'
            })
        };
    }
    getAddEditHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-type': 'application/json',
                Authorization: this.getKeyCloakToken()
            })
        };
    }
    fetchKeyCloakToken(reqObj) {
        return this.http.post(urlConstants.GET_ACCESS_TOKEN, JSON.stringify(reqObj), this.getApplicationHeaders());
    }
    userLogin(userData) {
        return this.http.post(urlConstants.LOGIN_API, JSON.stringify(userData), this.getAddEditHeaders());
    }
    setUserDetails(userDetails) {
        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
        this.userDetails = userDetails;
    }
    getUserDetails() {
        this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
        return this.userDetails;
    }
    userLogout(reqObj) {
        return this.http.post(urlConstants.LOGOUT_API, JSON.stringify(reqObj), this.getAddEditHeaders());
    }
    getEventData(userData) {
        return this.http.post(urlConstants.GET_EVENT_DATA, JSON.stringify(userData), this.addhttpOptions);
    }
}
LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: LoginService, providedIn: "root" });
