import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, delay, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable, Injector } from '@angular/core';
import { LoaderService } from './services/loader.service';
import { ConfigService } from 'src/app/config.service';
import { Router } from '@angular/router';
import { appLevelConstants } from 'src/app/shared/configurations/app-level-constants';
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  userInfo = this.configService.getLoggedInUserInfo();
  appLevelConstants = appLevelConstants;
  showBackGround: boolean;
  showHead: boolean;
  bgClasname: string;
  refresh_time: any;
  endDate: Date;
  seconds: number;

  constructor(
    private toaster: ToastrService,
    private injector: Injector,
    private router: Router,
    private configService: ConfigService
  ) { }

  currentTime = new Date().getTime();
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      let logged_in_user_data = JSON.parse(localStorage.getItem('loggedInUserInfo'))
      let requestBody = typeof (request.body) == 'object' ? request.body : JSON.parse(request.body)
      let append_object = {}
      if (logged_in_user_data && logged_in_user_data.user_id) {
        append_object['auser_name'] = logged_in_user_data.user_id
      }
      if (append_object['auser_name']) {
        request = request.clone({
          'body': { ...requestBody, ...append_object }
        })
      }
    }
    catch(err){
      console.log('inside interceptor catch',err)
    }
    const loaderService = this.injector.get(LoaderService);
    return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.status === 200 && event.body) {
              // today data and time and request object to api
              const today = new Date();
              const reqData = request;              // for firebase interceptor
              this.refresh_time = Date.parse(localStorage.refresh_time);
              this.endDate = new Date(this.refresh_time);
              this.checkLoggedInTime(today);

              // if (this.seconds > 1800 && !event['url'].includes('login')) {
              //   this.router.navigateByUrl('/login');
              //   this.showHead = false;
              //   this.showBackGround = true;
              //   this.bgClasname = 'lyt-main';
              //   localStorage.clear();
              //   window.location.href = "/";
              //   this.toaster.error("Idle time has been expired", '', {
              //     timeOut: 5000
              //   });

              if(request.url.includes('update-admin-login-request-status')){
                return event;
              }

              if (event.body.status === 0 && (event.body.hasOwnProperty('result') == false) && (request.url.includes('add'))) {
                // this.checkLoggedInTime(today);
                this.toaster.success(event.body.message, '', {
                  timeOut: 5000,
                });
              }

              else if (event.body.status === 0 && (event.body.hasOwnProperty('result') == false) && (request.url.includes('update') ||
                request.url.includes('delete') || request.url.includes('blacklist') || request.url.includes('create') || request.url.includes('cancel') || request.url.includes('user-login') && !request.url.includes('vehicle-state-data') || request.url.includes('vehicle-telementry-data') || request.url.includes('get-vehicle-list-by-fleetowner-email')) 
                && !request.url.includes('update-admin-configuration')
                && !request.url.includes('get-fuel-alert-list')
                && !request.url.includes('fuel-odometer-data')

                ) {
                // this.checkLoggedInTime(today);
                this.toaster.error(event.body.message, '', {
                  timeOut: 5000,
                });
              }

              else if (event.body.status === 0 && (request.url.includes('add') || request.url.includes('update') ||
                request.url.includes('delete') || request.url.includes('blacklist') || request.url.includes('create') || request.url.includes('cancel') || request.url.includes('user-login') && ! request.url.includes('vehicle-state-data') || request.url.includes('vehicle-telementry-data') || request.url.includes('get-vehicle-list-by-fleetowner-email'))
                && !request.url.includes('get-fuel-alert-list') 
                && !request.url.includes('fuel-odometer-data')
                
                ) {
                // this.checkLoggedInTime(today);
                this.toaster.success(event.body.message, '', {
                  timeOut: 5000,
                });
              }

              else if (event.body.status === 1 && (request.url.includes('add') || request.url.includes('update') ||
                request.url.includes('delete') || request.url.includes('blacklist') || request.url.includes('create') || request.url.includes('cancel') || request.url.includes('user-login') || request.url.includes('vehicle-state-data') || request.url.includes('vehicle-telementry-data') || request.url.includes('get-vehicle-list-by-fleetowner-email'))
                && !request.url.includes('get-fuel-alert-list') 
                && !request.url.includes('fuel-odometer-data')

                ) {
                // this.checkLoggedInTime(today);
                this.toaster.error(event.body.message, '', {
                  timeOut: 5000
                });
              }

              else if (event.body.status === 4 && (request.url.includes('add') || request.url.includes('update') ||
                request.url.includes('delete') || request.url.includes('blacklist') || request.url.includes('create') || request.url.includes('cancel') || request.url.includes('user-login') || request.url.includes('vehicle-state-data') || request.url.includes('vehicle-telementry-data') || request.url.includes('get-vehicle-list-by-fleetowner-email'))) {
                // this.checkLoggedInTime(today);
                this.toaster.error(event.body.message, '', {
                  timeOut: 5000
                });
              }
            }
            return event;
          }
        }),

        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.status === 401) {
            if (error.error.hasOwnProperty('exp')) {
              if (error.error['exp'] == 'token expired') {
                localStorage.clear();
                window.location.href = '/login';
              }
            } else if (this.router.url.includes('auth')) {
              errorMessage = 'Invalid Username or Password';
            } else {
              if ((request.url.includes('add') || request.url.includes('update'))) {
                errorMessage = 'Not able to get data';
              }
            }
          } else if (error.status === 404) {
            errorMessage = 'Page not found';
          } else {
            errorMessage = `${error.status}\nMessage: ${error.message}`;
          }
          loaderService.hide();
          return throwError(error);
        }),
        delay(1000),
        finalize(() => loaderService.hide())
      );
  }
  checkLoggedInTime(today) {
    this.seconds = (today.getTime() - this.endDate.getTime()) / 1000;
  }
}


