<div class="lyt-main" id="lyt-main">


<section>
    <div class="cp-section">
	
        <div class="sec-head full-width-lw">
            <div class="left-wrap" >
			 <button class="prev-btn" [routerLink]="['/client']"><span class="icon icon-left-arrow"></span></button>
                <h2 class="sec-title">Client Registration </h2>
            </div>
            <div class="right-wrap">
               
            </div>
        </div>
		<div class="sec-head full-width-lw" >
		   <div class="right-wrap ">
                <ul class="act-list typ-multiline">
				
				<li class="act-item typ-full">
					
								
								
								<div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
                                     <!-- <input  [min]="min" [max]="max" [(ngModel)]="start_date" [selectMode]="'range'"  class="form-control" placeholder="Start Date & End Date" (ngModelChange)="onEditStartDateChanged($event)" > -->
                                     <!-- <owl-date-time #dt10></owl-date-time> -->
									 
                                </div>
                            </div>
                        </div>
								
								
                        
                    </li>
                    
                    <li class="act-item typ-full">					
								
								
                        <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group" >
                                     <!-- <input type="text"  [(ngModel)]="clientName" class="form-control" placeholder="Client Name" (keyup)="applyFilter($event.target.value)" value=""> -->
                                </div>
                            </div>
                        </div>
                    </li>
					
					
					
					</ul>
					<ul class="act-list typ-multiline">
				
				<li class="act-item typ-full">
					
								
								
								<div class="cp-form">
                            <div class="form-group">
                                <div class="input-group">
                                     <!-- <input type="text"  [(ngModel)]="clientEmail" class="form-control" placeholder="Client Email" (keyup)="applyFilter($event.target.value)" value=""> -->
									 
                                </div>
                            </div>
                        </div>
								
								
                        
                    </li>
                    
                    <li class="act-item typ-full">					
								
								
                        <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group" >
                                     <!-- <input type="text"  [(ngModel)]="clientMobile" class="form-control" placeholder="Client Mobile" (keyup)="applyFilter($event.target.value)" value=""> -->
                                </div>
                            </div>
                        </div>
                    </li>
					
					
					
					</ul>
					<ul class="act-list typ-multiline" style="float:right;">
                    <!--li class="act-item">
                        <button class="btn btn-icon-text" (click)="fetchReport();" >
						<span class="icon icon-search"></span>Search</button>				
						
						
                    </li-->
                    <li class="act-item">
                    
						
						
						
						<div class="act-wrap">
                           <button class="btn btn-default" (click)="fetchReport();">Submit</button>
            
                        </div>
                    </li>
                  </ul>
            </div>
		</div>
		
        <div class="sec-cont">
            <div class="cp-card typ-table">
               <div class="mat-elevation-z8 table-responsive" *ngIf="resultCheck">
			  <table class="table table-bordered">
				<thead>
				<tr>
					<th>Sr No</th>
					<th>Spn Name</th>
					<th>Event Datetime</th>					
					<th>Value</th>
					<th>Spn Number</th>
					<!--th>SpnValue Description</th>
					<th>Spn Value</th>
					<!--th>GpsLongitude</th-->
				</tr>
				</thead>
				<tbody>
				
				
				
				<!-- <tr *ngFor="let item of event_array  let i=index"> -->
					<!-- <td>{{ i+1}}</td>
					<td><div style="width:100px;word-wrap: break-word;">{{item.spnName}}</div></td>
					<td><div style="width:100px;word-wrap: break-word;">{{item.eventDateTime}}</div></td>
					
					<td>{{ item.value}}</td>
					<td><div style="width:100px;word-wrap: break-word;">{{item.spnNumber}}</div></td> -->
					<!--td><div style="width:100px;word-wrap: break-word;">{{item.spnDat[0].spnValueDescription}}</div></td-->
					
					<!--td><div style="width:100px;word-wrap: break-word;">--</div></td-->
					
					
				<!-- </tr> -->
				</tbody>
			</table>	
			</div>
            </div>
        </div>
    </div>
   
</section>






