
<div class="typ-login">
  <div class="lyt-login">
  <div class="cp-onboarding">
    <div class="logo-wrap">
      <a href="#" class="logo"><img [src]="TM_LOGO" alt="" /></a>
    </div>
    <!-- Login Form -->
    <form [formGroup]="loginForm" class="card-body" class="formDiv" name="myForm" ng-controller="mainCtrl">
      <div class="login-form onboarding-step" *ngIf="loginStep">
        <h2 class="title">{{ loginTitle }}</h2>
        <h4 class="desc">{{ desc }}</h4>
        <div class="cp-form">
          <ul class="field-list">
          <!-- Role Field -->
          <li class="field-item col-item">
          <div class="form-group">
            <div class="input-group" >
              <p-dropdown placeholder="Select Roles" [options]="roles" optionLabel="userRole" id="role-dropdown"
                formControlName="roles" [ngClass]="{ 'is-invalid': loginSubmitted && formControls.roles.errors }">
              </p-dropdown>
            </div>
            <div *ngIf="(loginSubmitted || formControls.roles.dirty) && formControls.roles.errors" class="invalid-error">
              <div *ngIf="formControls.roles.errors.required">{{ errormessages.REQUIRED }}</div>
            </div>
          </div>
        </li>
        <!-- Role Field End -->
            <!-- Email Field -->
            <li *ngIf = "!supportRole" class="field-item col-item">
              <div class="form-group">
                <div class="input-group">
                  <input placeholder="Email" type="text" formControlName="emailId" name="username" #username pInputText
                    class="form-control" value = ""
                    [ngClass]="(loginSubmitted || formControls.emailId.dirty) && formControls.emailId.errors? 'is-invalid': ''" />
                </div>
                <div *ngIf="(loginSubmitted || formControls.emailId.dirty) && formControls.emailId.errors" class="invalid-error">
                  <span *ngIf="formControls.emailId.errors.required" class="text-danger">{{ errormessages.REQUIRED }}</span>
                  <span *ngIf="formControls.emailId.errors.pattern" class="text-danger">{{ errormessages.EMAIL_ID_IS_INVALID}}</span>
                </div>
              </div>
            </li>
            <!-- Email Field End -->
            <!-- Username Field Start-->
            <li *ngIf = "supportRole" class="field-item col-item">
              <div class="form-group">
                <div class="input-group">
                  <input #fullName [ngClass]="(loginSubmitted || formControls.userName.dirty) && formControls.userName.errors? 'is-invalid'
                   : ''" type="text" class="form-control font inputText" placeholder="User Name"
                    formControlName="userName" autocomplete="off" />
                  <div *ngIf="(loginSubmitted || formControls.userName.dirty) && formControls.userName.errors"
                    class="invalid-error">
                    <span *ngIf="formControls.userName.errors.required" class="text-danger">{{
                      errormessages.REQUIRED }}<br /></span>
                    <span *ngIf="formControls.userName.errors.minlength" class="text-danger">{{
                      errormessages.ATLEAST_4_CHARACTERS }}<br /></span>
                    <span *ngIf="formControls.userName.errors.maxlength" class="text-danger">{{
                      errormessages.ATMOST_50_CHARACTERS }}<br /></span>
                  </div>
                </div>
              </div>
            </li>
            <!-- Username field end -->
            
            <!-- Password Field -->
            <li class="field-item col-item">
              <div class="form-group with-icon">
                <div class="input-group">
                  <input placeholder="Password" type="{{ showPwd ? 'text' : 'password' }}" formControlName="password"
                    name="password" id="password" #pw pInputText class="form-control"
                    [ngClass]="{ 'is-invalid': loginSubmitted && formControls.password.errors }" />
                  <div class="icon icon-eye view-pwd view-pwd-margin" (click)="showPwd = !showPwd"></div>
                </div>
                <div *ngIf="(loginSubmitted || formControls.password.dirty) && formControls.password.errors? 'is-invalid' : ''"
                  class="invalid-error">
                  <div *ngIf="formControls.password.errors.required">{{ errormessages.REQUIRED }}</div>
                  <div *ngIf="formControls.password.errors?.minlength">{{ errormessages.LOGIN_PASSWORD_MIN_LENGTH }}</div>
                  <div *ngIf="formControls.password.errors?.maxlength">{{ errormessages.LOGIN_PASSWORD_MAX_LENGTH }}</div>
                </div>
              </div>
            </li>
            <!-- Password Field End -->
            <div class="invalid-credentials" *ngIf="invalidCredentialsView">{{ invalidCredentialsView }}</div>
            <!-- Terms and Conditions Section -->
            <li class="field-item col-item">
              <div class="form-group" id="invalid-error">
                <div for="" class="terms-and-conditions ">
                  <span id="chk-box"><input type="checkbox" (change)="changeSelection()" [checked]="selected" /></span>
                  {{ termConditions }}</div>
              </div>
              <div *ngIf="checked" class="invalid-error">
                {{errormessages.TERMS_AND_CONDITIONS_REQUIRED}}
              </div>
            </li>
            <!-- Terms and Conditions Section End -->
          </ul>
          <!-- Login Button -->
          <div class="btn-wrap">
            <button (click)="login()" class="btn btn-default" type="submit"
              id="submiLogin">{{loginButton}}</button>
          </div>
          <!-- Login Button End -->
        </div>
      </div>
    </form>
    <!-- Login Form End -->
  </div>
</div>
  <!-- Loader  -->
  <ngx-loading [show]="showLoading"></ngx-loading>
  <!-- Loader End -->
</div>