import { HttpHeaders } from "@angular/common/http";
//import 'rxjs/add/operator/map';
import { map } from 'rxjs/operators';
//import 'rxjs/add/operator/toPromise';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { urlConstants } from './shared/configurations/url-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
export class BaseApiService {
    constructor(http, router) {
        this.http = http;
        this.router = router;
        this.loggedIn = new BehaviorSubject(false); // {1}
        // baseUrl: string = 'https://cvp-dev.api.tatamotors/cvp/';//Dev
        //  baseTokenUrl: string ='https://cvp-dev.api.tatamotors/auth/realms/service_account/protocol/openid-connect/token';//Dev
        this.logoutURL = ''; //prod
        this.baseUrl = 'https://cvp.api.tatamotors/cvp/'; //prod  
        //MAT505159L8G05397  MAT505159L8G05397
        // baseTokenUrl: string ='https://cvpauth.api.tatamotors/auth/realms/service_account/protocol/openid-connect/token';//prod
        // we have to uncomment above urls.(demo purpose)
        this.baseTokenUrl = urlConstants.LOGIN_API;
        //****************dev details*************************************************
        this.onboardUrl = 'https://cvp-dev.api.tatamotors/cv/vehicle-state/onboarded';
        this.onboardVinUrl = 'https://cvp-dev.api.tatamotors/';
    }
    postLogin(URL, data) {
        const HttpUploadOptions = {
            headers: new HttpHeaders({ "Content-Type": "application/x-www-form-urlencoded" })
        };
        return this.http.post(URL, data, HttpUploadOptions).pipe(map(res => res));
    }
    // postLogin(URL:any,data:any){
    //   return this.http.post(URL,data)
    // }
    getTustedToken(url, dataString) {
        return new Promise((resolve, reject) => {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': 'text/plain, */*',
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }),
                responseType: 'text'
            };
            this.http.post(url, dataString, httpOptions).subscribe(data => {
                //  var jsonObj = JSON.parse(JSON.stringify(data.json()));
                resolve(data);
            }, error => {
                reject(error);
            });
        });
    }
    callServiceApi(url, dataString) {
        return new Promise((resolve, reject) => {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': '*/*',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                })
            };
            var finalUrl = url + dataString;
            this.http.get(finalUrl, httpOptions).subscribe((data) => {
                resolve(data);
            }, error => {
                reject(error);
            });
        });
    }
    callServiceApiXL(url, dataString) {
        return new Promise((resolve, reject) => {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Accept': '*/*',
                    //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }),
                responseType: 'text'
            };
            var finalUrl = url + dataString;
            this.http.get(finalUrl, httpOptions).subscribe((data) => {
                // var jsonObj = JSON.parse(JSON.stringify(data.json()));
                resolve(data);
            }, error => {
                var jsonObj = JSON.parse(JSON.stringify(error));
                reject(error);
            });
        });
    }
    callOnboardApi(url, dataString) {
        return new Promise((resolve, reject) => {
            const httpOptions = {};
            var finalUrl = url + dataString;
            this.http.get(finalUrl, httpOptions).subscribe((data) => {
                resolve(data);
            }, error => {
                reject(error);
            });
        });
    }
    callOnboardPostApi(url, dataString) {
        return new Promise((resolve, reject) => {
            const httpOptions = {};
            this.http.post(url, dataString, httpOptions).subscribe((data) => {
                resolve(data);
            }, error => {
                reject(error);
            });
        });
    }
    get isLoggedInUser() {
        this.login();
        return this.loggedIn.asObservable(); // {2}
    }
    login() {
        var token = localStorage.getItem('token');
        if (token != undefined && token != '') {
            this.loggedIn.next(true);
        }
        else {
            this.loggedIn.next(false);
        }
    }
}
BaseApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseApiService_Factory() { return new BaseApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: BaseApiService, providedIn: "root" });
