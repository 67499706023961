import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { checkPattern } from 'src/app/shared/configurations/pattern-constants';
import { Validators } from '@angular/forms';
import * as moment from 'moment';
import { errorMessages } from '@shared/configurations/error-messages-constants';
import { Workbook } from 'exceljs';
import { appLevelConstants } from 'src/app/shared/configurations/app-level-constants';
import * as fs from 'file-saver';
import { ASCENDING_SVG, ASCENDING_GREY_SVG, DESCENDING_GREY_SVG, DESCENDING_SVG } from '@shared/configurations/image-constants';
export class AdminRequestComponent {
    constructor(router, formBuilder, adminrequestservice, toaster) {
        this.router = router;
        this.formBuilder = formBuilder;
        this.adminrequestservice = adminrequestservice;
        this.toaster = toaster;
        this.ASCENDING_SVG = ASCENDING_SVG;
        this.ASCENDING_GREY_SVG = ASCENDING_GREY_SVG;
        this.DESCENDING_GREY_SVG = DESCENDING_GREY_SVG;
        this.DESCENDING_SVG = DESCENDING_SVG;
        this.reqObj2 = {
            user_name: "",
            sort_by: "asc",
            page_number: 1,
            from_datetime: "",
            to_datetime: "",
        };
        this.checkPattern = checkPattern;
        this.imageLink = "../../../../assets/images";
        this.adminRequestData = [];
        this.showTableData = false;
        // dropdownList = [];
        this.errorMessages = errorMessages;
        this.showLoading = false;
        this.isAdminFormInValid = false;
        this.isStatusFilterEmpty = false;
        this.adminRequestStatus = "";
        this.selectedItems = [];
        this.selectedDropdownItem = [];
        this.mainresult_array = [];
        this.globalData = [];
        //dropdownSettings: IDropdownSettings;
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'filterRequests',
            limitSelection: 5,
            enableCheckAll: false,
            allowSearchFilter: false,
            itemsShowLimit: 2
        };
        this.columns = ["Admin ID", "Ticket Number", 'Reason for Creation', 'Request Status', 'Created On', 'Last Updated'];
        this.heading = [
            {
                name: "Admin ID",
                value: "email_id",
                isAscBlue: false,
                isDescBlue: false,
            },
            {
                name: "Ticket Number",
                value: "ticket_no",
                isAscBlue: false,
                isDescBlue: false,
            },
            {
                name: 'Reason for Creation',
                value: 'reason_for_creation',
                isAscBlue: false,
                isDescBlue: false,
            },
            {
                name: 'Request Status',
                value: 'request_status',
                isAscBlue: false,
                isDescBlue: false,
            },
            {
                name: 'Created On',
                value: 'created_at',
                isAscBlue: false,
                isDescBlue: false,
            },
            {
                name: 'Last Updated',
                value: 'last_updated_at',
                isAscBlue: false,
                isDescBlue: false,
            }
        ];
        this.dropdownList = [
            {
                id: 1,
                filterRequests: 'Approved',
            },
            {
                id: 2,
                filterRequests: 'Waiting',
            },
            {
                id: 3,
                filterRequests: 'Rejected',
            },
            {
                id: 4,
                filterRequests: 'Auto Approved',
            },
            {
                id: 5,
                filterRequests: 'Auto Rejected',
            }
        ];
        this.locale = {
            format: 'DD/MM/YYYY HH:mm:ss',
            firstDay: 1
        };
        this.fromDate = moment().subtract(1, 'month');
        this.todayDate = moment();
    }
    get fc() {
        return this.adminlogQuery.controls;
    }
    ngOnInit() {
        this.adminlogQuery = this.formBuilder.group({
            adminId: ['', [Validators.required,]],
            requestStatus: ['', [Validators.required]],
            dateTime: [''],
        });
    }
    pass() {
    }
    onItemSelect(item) {
        let stringarray = this.selectedItems.map((item) => {
            return item.filterRequests;
        });
        this.selectedDropdownItem.push(item);
        this.checkRequestStatus();
    }
    onItemDeSelect(item) {
        for (var i = 0; i < this.selectedDropdownItem.length; i++) {
            if (this.selectedDropdownItem[i] === item.alert_type) {
                this.selectedDropdownItem.splice(i, 1);
            }
        }
        this.checkRequestStatus();
    }
    checkAdminFormValidation() {
        let temp = this.fc.adminId.value;
        if (this.checkPattern.EMAIL_VALIDATION.test(temp)) {
            this.isAdminFormInValid = false;
        }
        else {
            this.isAdminFormInValid = true;
        }
    }
    checkRequestStatus() {
        let temp = this.selectedItems.map((item) => { return item.filterRequests; });
        if (temp.length != 0) {
            this.isStatusFilterEmpty = false;
        }
        else {
            this.isStatusFilterEmpty = true;
        }
    }
    fetchReport(sortBy, page_number) {
        this.checkAdminFormValidation();
        this.checkRequestStatus();
        this.submitted = true;
        this.showLoading = true;
        if (!(!this.isAdminFormInValid && !this.isStatusFilterEmpty)) {
            this.showLoading = false;
            return;
        }
        else {
            let fromdate, todate = '';
            if (this.fc.dateTime.value.startDate == undefined) {
                fromdate = '';
                todate = '';
            }
            else {
                fromdate = this.fc.dateTime.value.startDate.toDate().toISOString().slice(0, 19);
                todate = this.fc.dateTime.value.endDate.toDate().toISOString().slice(0, 19);
            }
            this.submitted = true;
            this.reqObj2 = {
                'page_number': page_number ? page_number : 1,
                'user_name': this.fc.adminId.value,
                'sort_by': sortBy,
                'filter_by_status': this.selectedItems.map((item) => {
                    return item.filterRequests;
                }),
                'from_datetime': fromdate,
                'to_datetime': todate
            };
            this.adminrequestservice.getAdminRequest(this.reqObj2).subscribe((response) => {
                this.showLoading = false;
                if (response.status === 0 && response.hasOwnProperty("result") === true) {
                    if (response.result.length <= 0) {
                        this.showTableData = false;
                        this.toaster.error("No record found", '', {
                            timeOut: 5000
                        });
                    }
                    else {
                        this.adminRequestData = response.result;
                        this.totalRecords = response.total_count;
                        this.showTableData = true;
                        this.toaster.success(response.message, '', {
                            timeOut: 5000
                        });
                    }
                }
                else {
                    this.showTableData = false;
                    this.toaster.error(response.message, '', {
                        timeOut: 5000
                    });
                }
            }, (error) => {
                this.showLoading = false;
                this.toaster.error(error.message, '', {
                    timeOut: 5000
                });
            });
        }
    }
    sort(field, type) {
        this.fetchReport(type, null);
    }
    getUpDownChange(colname, sort) {
        this.heading.forEach((element) => {
            if (element.value === colname) {
                if (sort === "asc") {
                    element.isAscBlue = true;
                    element.isDescBlue = false;
                }
                else {
                    element.isDescBlue = true;
                    element.isAscBlue = false;
                }
            }
            else {
                element.isAscBlue = false;
                element.isDescBlue = false;
            }
        });
    }
    timechangeFunction(any) {
        return new Date(any).toLocaleString();
    }
    nextPage(page) {
        if (this.fc.adminId.value) {
            this.fetchReport(this.reqObj2.sort_by, page);
        }
    }
    numToAlpha(num) {
        let alpha = '';
        for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
            alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
        }
        return alpha;
    }
    generateXl() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("admin_log");
            // await worksheet.protect('tata-motors', { formatCells: true });
            worksheet.addRow([]);
            worksheet.mergeCells('A1:' + this.numToAlpha(this.columns.length - 1) + '1');
            worksheet.getCell('A1').value = appLevelConstants.TEXT_FOR_COPYRIGHT;
            worksheet.getCell('A1').alignment = { horizontal: 'center' };
            worksheet.getCell('A1').font = { bold: true };
            const headerRow = worksheet.addRow(this.columns).alignment = { wrapText: true };
            worksheet.columns = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 30 }, { width: 30 }];
            let columnsArray;
            for (const key in this.globalData) {
                if (this.globalData.hasOwnProperty(key)) {
                    columnsArray = Object.keys(this.globalData[key]);
                }
            }
            this.globalData.forEach((element) => {
                const eachRow = [];
                columnsArray.forEach((column) => {
                    eachRow.push(element[column]);
                });
                worksheet.addRow(eachRow).alignment = { wrapText: true };
            });
            workbook.xlsx.writeBuffer().then((data1) => {
                const blob = new Blob([data1], { type: 'xlsx' });
                fs.saveAs(blob, "admin-requests-" + this.fc.adminId.value + ".xlsx");
            });
            this.globalData = [];
        });
    }
    downloadAsExcelFile() {
        this.showLoading = true;
        this.checkAdminFormValidation();
        this.checkRequestStatus();
        this.submitted = true;
        if (!(!this.isAdminFormInValid && !this.isStatusFilterEmpty)) {
            return;
        }
        let fromdate, todate = '';
        if (this.fc.dateTime.value.startDate == undefined) {
            fromdate = '';
            todate = '';
        }
        else {
            fromdate = this.fc.dateTime.value.startDate.toDate().toISOString().slice(0, 19);
            todate = this.fc.dateTime.value.endDate.toDate().toISOString().slice(0, 19);
        }
        let reqObj2 = {
            'user_name': this.fc.adminId.value,
            'sort_by': 'desc',
            'filter_by_status': this.selectedItems.map((item) => {
                return item.filterRequests;
            }),
            'from_datetime': fromdate,
            'to_datetime': todate
        };
        this.adminrequestservice.getAdminRequest(reqObj2).subscribe((data) => {
            if (data.hasOwnProperty("result")) {
                this.showLoading = false;
                this.toaster.success("Data retrieved successfully", '', {
                    timeOut: 5000
                });
                this.mainresult_array = data.result;
                let jsonObj2 = {};
                this.globalData = [];
                for (let i = 0; i < data.result.length; i++) {
                    jsonObj2["Admin ID"] = data.result[i].email_id;
                    jsonObj2["Ticket Number"] = data.result[i].ticket_no;
                    jsonObj2["Reason for creation"] = data.result[i].reason_for_creation;
                    jsonObj2["Request Status"] = data.result[i].request_status;
                    jsonObj2["Created On"] = this.timechangeFunction(data.result[i].created_at);
                    jsonObj2["Last Updated"] = this.timechangeFunction(data.result[i].last_updated_at);
                    this.globalData.push(jsonObj2);
                    jsonObj2 = {};
                }
                if (this.globalData.length > 0) {
                    this.generateXl();
                }
                else {
                    this.toaster.error(data.message, '', {
                        timeOut: 5000
                    });
                }
            }
            else {
                this.showLoading = false;
                this.toaster.error(data.message, '', {
                    timeOut: 5000
                });
            }
        }, (err) => {
            this.showLoading = false;
            var jsonObj = JSON.parse(JSON.stringify(err));
            var message = jsonObj.msg;
            if (message != undefined) {
                alert(message);
            }
            else {
                this.toaster.success("Please try again", '', {
                    timeOut: 5000
                });
            }
        });
    }
}
