// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { environmentKeys } from "environment-keys";

const environmentPath = environmentKeys.production

export const environment = {
  production: false,
  GOOGLE_MAPS_API_KEY: environmentPath.GOOGLE_MAPS_API_KEY,
  GEOCODE_KEY : environmentPath.GEOCODE_KEY,
  GEOCODE_URL: environmentPath.GEOCODE_URL,
  firebaseConfig : environmentPath.firebaseConfig,
  cloudFrontURL: environmentPath.cloudFrontURL,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
