<div class="lyt-main" id="lyt-main">


<section>
    <div class="cp-section">
	
        <div class="sec-head full-width-lw">
            <div class="left-wrap" >
                <h2 class="sec-title">Clients List </h2>
            </div>
            <div class="right-wrap">
               
            </div>
        </div>
		<div class="sec-head full-width-lw" >
		   <div class="right-wrap ">
                <ul class="act-list typ-multiline">
				
				
                    
                    <li class="act-item typ-full">					
								
								
                        <div class="cp-form">
                            <div class="form-group">
                                <div class="input-group" >
                                     <!-- <input type="text"  [(ngModel)]="searchData" class="form-control" placeholder="Client Id" (keyup)="applyFilter($event.target.value)" value=""> -->
                                </div>
                            </div>
                        </div>
                    </li>
					<!--http://localhost:8080/cvp/telemetry-parameter/
					
					PROACC12091912303
					
					?fromDate=2020-08-25T10:03:05&toDate=2020-08-27T13:04:06&teleParam=total_fuel_used,total_distance-->
					
					
					
					
					
                    <li class="act-item">
                        <button class="btn btn-icon-text" (click)="fetchReport();" >
						<span class="icon icon-search"></span>Search</button>
						
						
						
                    </li>
                    <li class="act-item">
                        <button class="btn btn-icon-text" (click)="addClient();" ><span class="icon icon-add"></span>Add</button>
                    </li>
                </ul>
            </div>
		</div>
		
        <div class="sec-cont">
            <div class="cp-card typ-table">
               <div class="mat-elevation-z8 table-responsive" *ngIf="resultCheck" style="overflow-y: scroll; height:400px;">
			  <table class="table table-bordered" style="overflow-y: scroll; height:400px;">
				<thead>
				<tr>
					<th>Sr No</th>
					<th>Client Id</th>
					<th>Name</th>					
					<th>Start Date</th>
					<th>End Date</th>
					<th>API List</th>
					<th></th>
					<!--th>Spn Value</th>
					<!--th>GpsLongitude</th-->
				</tr>
				</thead>
				<tbody>
				<!---
				
				
				
				[{"clientId":"test_client","clientName":null},{"clientId":"test_client_dev","clientName":null},{"clientId":"d9ef9b86-8865-4a84-8a8b-667b9db3fdea","clientName":"test_client_1"},{"clientId":"edc318eb-e683-4e1a-819b-a0cdd9513325","clientName":"test_client_2"},{"clientId":"82529486-ec5e-4fa9-b6c3-d552cf1911f9","clientName":"test_client_3"},{"clientId":"73642b6a-0c22-4f27-b4bb-c339a544e289","clientName":"test_client_4"},{"clientId":"77a218a0-b06f-40dc-b353-289a3c1e13d0","clientName":"test_client_5"},{"clientId":"e1dca206-155f-48b5-b3ca-7664b76d3fbb","clientName":"test_client_6"},{"clientId":"7264678f-c6a7-446e-aec0-2d7dd39fe9ee","clientName":"test_client_7"},{"clientId":"a088854e-45ee-439c-ab48-c3387b1f1780","clientName":"test_client_8"},{"clientId":"6a3aa20e-1299-4093-8d6c-0a9b375bd448","clientName":"test_client_9"},{"clientId":"1c8465d0-4365-4b45-9804-58858983ee7e","clientName":"test_client_10"},{"clientId":"c52c56e6-0f58-487f-bed4-bbd05781c38e","clientName":"test_client_11"},{"clientId":"b9a41137-afc3-4434-a676-5041cb0e484c","clientName":"Anirudha"},{"clientId":"83e55652-9874-44a5-8242-4ee52a66e522","clientName":"Anirudha Agrawal"},{"clientId":"7dbfca85-0f30-45c2-a3bb-3e4f34698753","clientName":"Anirudha Agrawal ddd"},{"clientId":"b595ef85-3904-4e44-992e-a3114d198069","clientName":"tmld_client_dev"},{"clientId":"794eddbc-8bd8-44d4-a4a1-9d385d9bc187","clientName":"separted_api_test"}]
				---->
				
				
				<tr *ngFor="let item of event_array  let i=index">
					<td>{{ i+1}}</td>
					<td><div style="width:100px;word-wrap: break-word;">{{item.clientId}}</div></td>
					<td><div style="width:100px;word-wrap: break-word;">{{item.clientName}}</div></td>
					
					<td></td>
					<td></td>
					<td><div style="width:100px;word-wrap: break-word;"></div></td>
					<!--td><div style="width:100px;word-wrap: break-word;">{{item.spnDat[0].spnValueDescription}}</div></td-->
					
					<td><div style="width:100px;word-wrap: break-word;">				
					
					 
							<a [routerLink]="['/vehicle']" [queryParams]="{clientId: item.clientId}" fragment="api">
							Add/View Api
							</a>
					 
					 
            
					
					</div></td>
					
					<!--td><div style="width:100px;word-wrap: break-word;">--</div></td-->
					
					
				</tr>
				</tbody>
			</table>	
			</div>
            </div>
        </div>
    </div>
   
</section>






<style>
/*
:host::ng-deep.selected-item { border-radius: 6px !important; background: #999999 !important; border: 1px solid #999999 !important; }

:host::ng-deep.selected-item {
border-radius: 6px !important;
background: #BAA28E !important;
border: 1px solid #BAA28E !important;
}

:host::ng-deep ng-multiselect-dropdown .multiselect-item-checkbox input[type=checkbox] + div:before {
border: 2px solid #5e3817 !important;
}

:host::ng-deep ng-multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked + div:before {
background: #BAA28E !important;
}

.custom-font-size{
  height:10000px;
}*/

:host::ng-deep multiselect-dropdown[_ngcontent-c5] .dropdown-btn[_ngcontent-c5] {
  border:0px;
}


:host::ng-deep.dropdown-btn { border-radius: 6px !important;  border: 0px solid #999999 !important;
padding: 0px 0px !important;
/* width: 400px !important;*/
 }

</style>
<!--footer>
			Copyright &#169; 2020 TATA MOTORS All Rights Reserved.
		</footer-->