import { urlConstants } from '@shared/configurations/url-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../config.service";
export class BlockedIdManagementService {
    constructor(http, configService) {
        this.http = http;
        this.configService = configService;
    }
    getUserStatus(data) {
        return this.http.post(urlConstants.CHECK_BLOCKED_FLEET_ID_STATUS, JSON.stringify(data), this.configService.getAddEditHeaders());
    }
    setUserStatusToUnblock(data) {
        return this.http.post(urlConstants.UNBLOCK_FLEET_ID_STATUS, JSON.stringify(data), this.configService.getAddEditHeaders());
    }
}
BlockedIdManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BlockedIdManagementService_Factory() { return new BlockedIdManagementService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: BlockedIdManagementService, providedIn: "root" });
