import { HttpClient } from '@angular/common/http';
import { urlConstants } from '@shared/configurations/url-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../config.service";
export class AdminLogService {
    constructor(http, configService) {
        this.http = http;
        this.configService = configService;
    }
    getAdminLog(adminInfo) {
        return this.http.post(urlConstants.ADMIN_LOG, adminInfo, this.configService.getAddEditHeaders());
    }
    getAdminData(adminInfo) {
        return this.http.post(urlConstants.ADMIN_LOG, adminInfo, this.configService.getAddEditHeaders());
    }
    updateAdminLoginReuestStatus(req) {
        return this.http.post(urlConstants.UPDATE_ADMIN_LOGIN_REQUEST, req, this.configService.getAddEditHeaders());
    }
}
AdminLogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminLogService_Factory() { return new AdminLogService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: AdminLogService, providedIn: "root" });
