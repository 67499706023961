import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ConfigService } from './config.service';
import { appLevelConstants } from './shared/configurations/app-level-constants';
import { urlConstants } from './shared/configurations/url-constants';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public isLoggedIn: boolean = true;
  public onboardingStep: boolean = false;
  userDetails: any;
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) { }

  httpOptions = this.configService.getApplicationHeaders();
  addhttpOptions = this.configService.getAddEditHeaders();

  getKeyCloakToken() {
    return 'Bearer ' + localStorage.getItem('auth_token');
  }

  getApplicationHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: 'Basic Y3ZwLXVzZXItMTpwbHV0b2lzbm90YXBsYW5ldA'
      })
    };
  }
  getAddEditHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: this.getKeyCloakToken()
      })
    };
  }

  fetchKeyCloakToken(reqObj): Observable<any> {
    return this.http.post(urlConstants.GET_ACCESS_TOKEN, JSON.stringify(reqObj), this.getApplicationHeaders());
  }

  userLogin(userData): Observable<any> {
    return this.http.post(urlConstants.LOGIN_API, JSON.stringify(userData), this.getAddEditHeaders());
  }

  setUserDetails(userDetails) {
    sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
    this.userDetails = userDetails;
  }

  getUserDetails() {
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    return this.userDetails;
  }

  userLogout(reqObj): Observable<any> {
    return this.http.post(urlConstants.LOGOUT_API, JSON.stringify(reqObj), this.getAddEditHeaders());
  }
   
  getEventData(userData): Observable<any> {
    return this.http.post(urlConstants.GET_EVENT_DATA, JSON.stringify(userData),  this.addhttpOptions);
  }

}
