<div class="lyt-main" id="lyt-main">
    <div class="cp-section">
        <form action="" [formGroup]="vehicleCrmForm">
            
            <div class="sec-head full-width-lw ">
                <div class="left-wrap">
                    <h2 class="sec-title">Vehicle List - CRM</h2>
                </div>
            </div>
            <div class="sec-head full-width-lw">
                <div class="right-wrap ">
                    <ul class="row typ-multiline">
                        <li class="act-item typ-full">
                            <div class="cp-form">
                                <div class="form-group">
                                    <label for="" class="form-label">{{mobileLable}} </label>
                                    <div class="input-group">
                                        <input type="tel" class="form-control" placeholder="Mobile Number"
                                            formControlName="mobileNumber" pInputText class="form-control"
                                            maxlength="10"
                                            [ngClass]="(submitted || getformControls.mobileNumber.dirty) && getformControls.mobileNumber.errors ? 'is-invalid':''">
                                        <div *ngIf="(submitted || getformControls.mobileNumber.dirty) && getformControls.mobileNumber.errors"
                                            class="invalid-error">
                                            <div *ngIf="getformControls.mobileNumber.errors.required">
                                                {{errorMessages.FIELD_IS_REQUIRED}}
                                            </div>
                                            <div *ngIf=" getformControls.mobileNumber.errors.pattern ">
                                                {{errorMessages.MOBILE_NO_IS_INVALID}}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class=" act-item typ-full">
                            <div class="cp-form">
                                <div class="form-group">
                                    <label for="" class="form-label">{{panLabel}}</label>
                                    <div class="input-group">
                                        <input type="text" formControlName="panCardNumber" class="form-control"
                                            placeholder="PAN" maxlength="10"
                                            [ngClass]="(submitted || getformControls.panCardNumber.dirty) && getformControls.panCardNumber.errors ? 'is-invalid' : ''">

                                        <div *ngIf="(submitted || getformControls.panCardNumber.dirty) && getformControls.panCardNumber.errors"
                                            class="invalid-error">
                                            <div *ngIf="getformControls.panCardNumber.errors.required">
                                                {{errorMessages.FIELD_IS_REQUIRED}}</div>
                                            <div *ngIf="getformControls.panCardNumber.errors.pattern">
                                                {{errorMessages.PAN_IS_INVALID}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class=" act-item typ-full">
                            <div class="cp-form">
                                <div class="form-group">
                                    <button type="submit" class="btn btn-default add-btn mt-5" (click)="submitData();">
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </form>

        <app-query-result *ngIf="showTableData" [columnData]="columns" [tableData]="vinListarray"
            [currentPage]="reqObj.page_number" [totalRecords]="totalRecords" (pageChange)="nextPage($event)">
        </app-query-result>
    </div>
</div>