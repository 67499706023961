import { HttpHeaders } from '@angular/common/http';
import { urlConstants } from 'src/app/shared/configurations/url-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../config.service";
export class UserProfileService {
    constructor(http, configService) {
        this.http = http;
        this.configService = configService;
        this.httpOptions = this.configService.getApplicationHeaders();
        this.addhttpOptions = this.configService.getAddEditHeaders();
    }
    // API Call to get user details
    activeUserCount(userData) {
        return this.http.post(urlConstants.GET_ACTIVE_USERS, JSON.stringify(userData), this.addhttpOptions);
    }
    // set user object
    setUserObject(userObject) {
        this.userBeingProcessed = userObject;
    }
    // get user object
    getUserObject() {
        return this.userBeingProcessed;
    }
    getKeyCloakToken() {
        return 'Bearer ' + localStorage.getItem('auth_token');
    }
    getAddEditHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-type': 'application/json',
                Authorization: this.getKeyCloakToken()
            })
        };
    }
}
UserProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserProfileService_Factory() { return new UserProfileService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: UserProfileService, providedIn: "root" });
