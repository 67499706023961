import { HttpClient } from '@angular/common/http';
import { urlConstants } from '@shared/configurations/url-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../config.service";
export class AdminRequestService {
    constructor(http, configService) {
        this.http = http;
        this.configService = configService;
    }
    getAdminRequest(adminInfo) {
        return this.http.post(urlConstants.ADMIN_REQUEST, adminInfo, this.configService.getAddEditHeaders());
    }
}
AdminRequestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminRequestService_Factory() { return new AdminRequestService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: AdminRequestService, providedIn: "root" });
