import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../config.service";
import * as i2 from "ngx-toastr";
export class AuthGuard {
    constructor(configService, toaster) {
        this.configService = configService;
        this.toaster = toaster;
        this.showHead = false;
        this.showBackGround = false;
        this.bgClasname = "lyt-main";
    }
    // Call check authenticated user api request when user is active
    canActivate(route) {
        const userDetails = this.configService.getLoggedInUserInfo();
        let grant = !!localStorage.getItem("auth_token") && !!userDetails;
        if (!grant) {
            localStorage.clear();
            window.location.href = '/login';
            this.showHead = false;
            this.showBackGround = false;
            this.bgClasname = "lyt-main";
            return false;
        }
        return grant;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.ConfigService), i0.ɵɵinject(i2.ToastrService)); }, token: AuthGuard, providedIn: "root" });
