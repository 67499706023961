import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { IMyDpOptions, IMyDate } from 'mydatepicker';
import { checkPattern } from 'src/app/shared/configurations/pattern-constants';
import { Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { errorMessages } from 'src/app/shared/configurations/error-messages-constants';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { appLevelConstants } from 'src/app/shared/configurations/app-level-constants';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ASCENDING_SVG, ASCENDING_GREY_SVG, DESCENDING_GREY_SVG, DESCENDING_SVG } from '@shared/configurations/image-constants';
export class AdminLogComponent {
    constructor(router, spinnerService, toaster, formBuilder, http, adminlogservice) {
        this.router = router;
        this.spinnerService = spinnerService;
        this.toaster = toaster;
        this.formBuilder = formBuilder;
        this.http = http;
        this.adminlogservice = adminlogservice;
        this.ASCENDING_SVG = ASCENDING_SVG;
        this.ASCENDING_GREY_SVG = ASCENDING_GREY_SVG;
        this.DESCENDING_GREY_SVG = DESCENDING_GREY_SVG;
        this.DESCENDING_SVG = DESCENDING_SVG;
        this.reqObj2 = {
            from_datetime: "",
            page_number: 1,
            sort_by: "asc",
            to_datetime: "",
            fleetowner_email: "",
        };
        this.waterMarkLabel = localStorage.getItem('user_id');
        this.checkPattern = checkPattern;
        this.showLoading = false;
        this.showTableData = false;
        this.useridfield = localStorage.getItem('role_type') == "Admin" ? true : false;
        this.errorMessages = errorMessages;
        this.selectedMoment = new Date();
        this.todayDate = moment();
        this.fromDate = moment().subtract(1, 'month');
        this.locale = {
            format: 'DD/MM/YYYY HH:mm:ss',
            firstDay: 1
        };
        this.globalData = [];
        this.mainresult_array = [];
        this.isAdminFormInValid = false;
        this.imageLink = "../../../../assets/images";
        this.completeResponsePopupVisible = false;
        this.watermarkLabel = localStorage.getItem('user_id');
        this.useridactivity = localStorage.getItem('user_id');
        this.dropdownList = [];
        this.selectedItems = [];
        this.resultCheck = false;
        this.pageOfItems = [];
        this.apps = [];
        this.min = new Date(2018, 1, 12, 10, 30);
        this.yearMax = this.getCurrentDay('Y');
        this.monthMax = this.getCurrentDay('M');
        this.dateMax = this.getCurrentDay('D');
        this.todate = new Date();
        this.Hours = this.todate.getHours();
        this.getMinutes = this.todate.getMinutes();
        this.max = new Date(this.yearMax, this.monthMax, this.dateMax, 20, 30);
        this.myDate = {
            year: this.getCurrentDay('Y'),
            month: this.getCurrentDay('M'),
            day: this.getCurrentDay('D'),
        };
        this.columns = ["Admin User ID", "API Name", 'API URL', 'Request Parameter', 'API Response', 'Created On'];
        this.heading = [
            {
                name: "Admin User ID",
                value: "user_name",
                isAscBlue: false,
                isDescBlue: false,
            },
            {
                name: "API Name",
                value: "api_name",
                isAscBlue: false,
                isDescBlue: false,
            },
            {
                name: 'API URL',
                value: 'api_url',
                isAscBlue: false,
                isDescBlue: false,
            },
            {
                name: 'Request Parameter',
                value: 'api_request',
                isAscBlue: false,
                isDescBlue: false,
            },
            {
                name: 'API Response',
                value: 'api_response',
                isAscBlue: false,
                isDescBlue: false,
            },
            {
                name: 'Created On',
                value: 'created_on',
                isAscBlue: false,
                isDescBlue: false,
            }
        ];
        this.myDatePickerOptions = {
            dateFormat: 'yyyy-mm-dd',
            markCurrentDay: true,
            disableSince: this.myDate
        };
        var token = localStorage.getItem('auth_token');
        if (token == undefined || token === '') {
            this.router.navigate(['/login']);
        }
        this.minDates = new Date();
        this.minDates.setDate(this.minDates.getDate() - 30);
    }
    getCurrentDay(cat) {
        let output;
        var today = new Date();
        var day = today.getDate();
        var month = today.getMonth() + 1; //January is 0!
        if (day != 1) {
        }
        else {
            if (month == 5 || month == 7 || month == 10 || month == 12) {
                day = 30;
            }
            if (month == 2 || month === 4 || month == 6 || month == 8 || month == 9 || month == 11 || month == 1) {
                day = 31;
            }
            if (year % 4 == 0 && month == 3) {
                day = 29;
            }
            else if (year % 4 != 0 && month == 3) {
                day = 28;
            }
            month = month - 1;
        }
        var year = today.getFullYear();
        if (cat === 'D') {
            output = day;
        }
        else if (cat === 'M') {
            output = month;
        }
        else {
            output = year;
        }
        return output;
    }
    compareDate() {
        var startDate = new Date(this.editStartDate);
        var endDate = new Date(this.editEndDate);
        var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
        if (endDate < startDate || seconds > 86400) {
            this.editStartDate = null;
            this.editEndDate = null;
            this.start_date = "";
        }
        else if (this.editEndDate != null && this.editStartDate != null) {
            this.editStartDate = this.convert(this.editStartDate);
            this.editEndDate = this.convert(this.editEndDate);
        }
    }
    convert(str) {
        var date = new Date(str), mnth = ("0" + (date.getMonth() + 1)).slice(-2), day = ("0" + date.getDate()).slice(-2);
        var Hours = date.getHours();
        var getMinutes = date.getMinutes();
        var returnDtae = [date.getFullYear(), mnth, day].join("-");
        returnDtae = returnDtae + "T" + Hours + ":" + getMinutes + ":00";
        return returnDtae;
    }
    onEditStartDateChanged(event) {
        var splitedDate = this.start_date.toString().split(",");
        this.editStartDate = splitedDate[0];
        this.editEndDate = splitedDate[1];
        if (this.editEndDate != null && this.editStartDate != null) {
            this.compareDate();
        }
    }
    get fc() {
        return this.adminlogQuery.controls;
    }
    get fv() {
        return this.adminlogQuery.value;
    }
    nextPage(page) {
        if (this.fc.adminId.value) {
            this.fetchReport(this.reqObj2.sort_by, page);
        }
    }
    ngOnInit() {
        this.adminlogQuery = this.formBuilder.group({
            adminId: ['', [Validators.email,]],
            apiName: ['', []],
            dateTime: ['', []]
        });
        this.monthMax = this.monthMax - 1;
        this.max = new Date(this.yearMax, this.monthMax, this.dateMax, this.Hours, this.getMinutes);
        let d = new Date();
        var sevenDaysAgo = d.setDate(d.getDate() - 30);
    }
    timechangeFunction(any) {
        return new Date(any).toLocaleString();
    }
    fetchReport(sortBy, page_number) {
        this.checkAdminFormValidation(this.fc.adminId.value);
        this.showLoading = true;
        this.submitted = true;
        if (this.isAdminFormInValid) {
            this.showLoading = false;
            return;
        }
        else {
            let fromdate, todate = '';
            if (this.fc.dateTime.value.startDate == undefined) {
                fromdate = '';
                todate = '';
            }
            else {
                fromdate = this.fc.dateTime.value.startDate.toDate().toISOString().slice(0, 19);
                todate = this.fc.dateTime.value.endDate.toDate().toISOString().slice(0, 19);
            }
            this.submitted = true;
            this.reqObj2 = {
                'user_name': this.fc.adminId.value,
                'sort_by': sortBy,
                'page_number': page_number ? page_number : 1,
                'search_api_name': this.fc.apiName.value,
                'from_datetime': fromdate,
                'to_datetime': todate
            };
            this.adminlogservice.getAdminLog(this.reqObj2).subscribe((response) => {
                this.showLoading = false;
                if (response.status === 0 && response.hasOwnProperty("result") === true) {
                    if (response.result.length <= 0) {
                        this.showTableData = false;
                        this.toaster.error("No record found", '', {
                            timeOut: 5000
                        });
                    }
                    else {
                        this.adminLogData = response.result;
                        this.totalRecords = response.total_count;
                        this.showTableData = true;
                        this.toaster.success(response.message, '', {
                            timeOut: 5000
                        });
                    }
                }
                else {
                    this.showTableData = false;
                    this.toaster.error(response.message, '', {
                        timeOut: 5000
                    });
                }
            }, (error) => {
                this.showLoading = false;
                this.toaster.error(error.message, '', {
                    timeOut: 5000
                });
            });
        }
    }
    sort(field, type) {
        this.fetchReport(type, null);
    }
    getUpDownChange(colname, sort) {
        this.heading.forEach((element) => {
            if (element.value === colname) {
                if (sort === "asc") {
                    element.isAscBlue = true;
                    element.isDescBlue = false;
                }
                else {
                    element.isDescBlue = true;
                    element.isAscBlue = false;
                }
            }
            else {
                element.isAscBlue = false;
                element.isDescBlue = false;
            }
        });
    }
    viewCompleteResponse(data) {
        this.selectedResponse = data;
        this.completeResponsePopupVisible = !this.completeResponsePopupVisible;
    }
    numToAlpha(num) {
        let alpha = '';
        for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
            alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
        }
        return alpha;
    }
    generateXl() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("admin_log");
            worksheet.addRow([]);
            worksheet.mergeCells('A1:' + this.numToAlpha(this.columns.length - 1) + '1');
            worksheet.getCell('A1').value = appLevelConstants.TEXT_FOR_COPYRIGHT;
            worksheet.getCell('A1').alignment = { horizontal: 'center' };
            worksheet.getCell('A1').font = { bold: true };
            const headerRow = worksheet.addRow(this.columns).alignment = { wrapText: true };
            worksheet.columns = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }];
            let columnsArray;
            for (const key in this.globalData) {
                if (this.globalData.hasOwnProperty(key)) {
                    columnsArray = Object.keys(this.globalData[key]);
                }
            }
            this.globalData.forEach((element) => {
                const eachRow = [];
                columnsArray.forEach((column) => {
                    eachRow.push(element[column]);
                });
                worksheet.addRow(eachRow).alignment = { wrapText: true };
            });
            workbook.xlsx.writeBuffer().then((data1) => {
                const blob = new Blob([data1], { type: 'xlsx' });
                fs.saveAs(blob, "admin-log-" + this.fc.adminId.value + ".xlsx");
            });
            this.globalData = [];
        });
    }
    downloadAsExcelFile() {
        this.checkAdminFormValidation(this.fc.adminId.value);
        this.showLoading = true;
        this.submitted = true;
        if (this.isAdminFormInValid) {
            this.showLoading = false;
            return;
        }
        let fromdate, todate = '';
        if (this.fc.dateTime.value.startDate == undefined) {
            fromdate = '';
            todate = '';
        }
        else {
            fromdate = this.fc.dateTime.value.startDate.toDate().toISOString().slice(0, 19);
            todate = this.fc.dateTime.value.endDate.toDate().toISOString().slice(0, 19);
        }
        let reqObj2 = {
            'user_name': this.fc.adminId.value,
            'sort_by': 'desc',
            'search_api_name': this.fc.apiName.value,
            'from_datetime': fromdate,
            'to_datetime': todate
        };
        this.adminlogservice.getAdminData(reqObj2).subscribe((data) => {
            this.showLoading = false;
            if (data.hasOwnProperty("result")) {
                this.toaster.success("Data retrieved successfully", '', {
                    timeOut: 5000
                });
                this.mainresult_array = data.result;
                let jsonObj2 = {};
                this.globalData = [];
                for (let i = 0; i < data.result.length; i++) {
                    jsonObj2["Admin User ID"] = data.result[i].user_name;
                    jsonObj2["API Name"] = data.result[i].api_name;
                    jsonObj2["API URL"] = data.result[i].api_url;
                    jsonObj2["Request Parameter"] = data.result[i].api_request;
                    jsonObj2["API Response"] = data.result[i].api_response;
                    jsonObj2["Created on"] = this.timechangeFunction(data.result[i].created_on);
                    this.globalData.push(jsonObj2);
                    jsonObj2 = {};
                }
                if (this.globalData.length > 0) {
                    this.generateXl();
                }
                else {
                    this.toaster.error(data.message, '', {
                        timeOut: 5000
                    });
                }
            }
            else {
                this.toaster.error(data.message, '', {
                    timeOut: 5000
                });
            }
        }, (err) => {
            this.showLoading = false;
            var jsonObj = JSON.parse(JSON.stringify(err));
            var message = jsonObj.msg;
            if (message != undefined) {
                alert(message);
            }
            else {
                this.toaster.success("Please try again", '', {
                    timeOut: 5000
                });
            }
        });
    }
    checkAdminFormValidation(value) {
        if (this.checkPattern.EMAIL_VALIDATION.test(value)) {
            this.isAdminFormInValid = false;
        }
        else {
            this.isAdminFormInValid = true;
        }
    }
}
