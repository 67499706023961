import { HttpClient } from '@angular/common/http';
import { urlConstants } from '@shared/configurations/url-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../config.service";
export class AdminAccessService {
    constructor(http, configService) {
        this.http = http;
        this.configService = configService;
    }
    getAdminAccessStatus(adminInfo) {
        return this.http.post(urlConstants.ADMIN_ACCESS_STATUS, adminInfo, this.configService.getAddEditHeaders());
    }
    setAdminAccessStatus(adminInfo) {
        return this.http.post(urlConstants.ADMIN_ACCESS_SET, adminInfo, this.configService.getAddEditHeaders());
    }
}
AdminAccessService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminAccessService_Factory() { return new AdminAccessService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: AdminAccessService, providedIn: "root" });
