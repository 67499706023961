import { HttpHeaders } from '@angular/common/http';
import { urlConstants } from 'src/app/shared/configurations/url-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../config.service";
export class UserActivityService {
    constructor(http, configService) {
        this.http = http;
        this.configService = configService;
        this.httpOptions = this.configService.getApplicationHeaders();
        this.addhttpOptions = this.configService.getAddEditHeaders();
    }
    // API call for fetching USER ACTIVITY DETAILS
    getUserActivity(userData) {
        return this.http.post(urlConstants.USER_ACTIVITY_DETAIL, JSON.stringify(userData), this.getAddEditHeaders());
    }
    getKeyCloakToken() {
        return 'Bearer ' + localStorage.getItem('auth_token');
    }
    getAddEditHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-type': 'application/json',
                Authorization: this.getKeyCloakToken()
            })
        };
    }
}
UserActivityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserActivityService_Factory() { return new UserActivityService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: UserActivityService, providedIn: "root" });
