<div class="lyt-main" id="lyt-main">
  <div class="cp-section">
    <ngx-loading [show]="showLoading"></ngx-loading>
    <app-query title="Edit Admin Access" (query)="fetchData()" [allowDownload]="false" [formGroup]="adminlogQuery">
      <ng-template InputGroup>
        <label for="" class="form-label">Admin ID</label>
        <div class="input-group">
          <input type="text" [ngClass]="{'form-label-invalid':isAdminFormInValid}"
            (keyup)="checkAdminFormValidation(adminid.value)" #adminid class="form-control font inputText"
            placeholder="Email" autocomplete="off" formControlName="adminId" />
        </div>
      </ng-template>
    </app-query>

    <div *ngIf="isAdminFormInValid" class="invalid-error">
      <div *ngIf="isAdminFormInValid">
        Enter Valid Admin ID
      </div>
    </div>

    <div *ngIf="showAccessStatus" class="cp-modal" >
      <p-accordion>
        <p-accordionTab *ngFor="let status of adminAccessData;let index = index"
          header="{{index+1}}.{{  status.feature_name }}" class="accordian-label accordian-no-border">

          <span *ngIf="showAccessStatus" class="no-margin role-type-label">
            <button (click)="enableAll(status,index)">Enable All</button>
          </span>

          <span *ngIf="showAccessStatus" class="no-margin role-type-label">
            <button (click)="disableAll(status,index)">Disable All</button>
          </span>

          <span *ngIf="showAccessStatus" class="no-margin role-type-label">
            <button (click)="accessmodify(status.feature_name,status)">Save</button>
          </span>

          <ng-template pTemplate="content" >
            <p-table *ngIf="showAccessStatus" [columns]="heading" [value]="status.api_list" [resizableColumns]="true" [autoLayout]="true">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let header of heading">
                    <span class="text">{{ header.name }}</span>
                  </th>
                </tr>
              </ng-template>
              <ng-template #recordsBlock pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td class="text-grey mat-column">
                    {{ rowData.api_url }}
                  </td>
                  <td class="text-grey mat-column">
                    <p-inputSwitch [(ngModel)]="rowData.access_for[0]">
                    </p-inputSwitch>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                  <td [attr.colspan]="columns.length">
                    "No Data Found"
                  </td>
                </tr>
              </ng-template>
            </p-table>

          </ng-template>

        </p-accordionTab>
      </p-accordion>
    </div>

  </div>
</div>