/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blocked-id-management.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../shared/query-result/query-result.component.ngfactory";
import * as i5 from "../../shared/query-result/query-result.component";
import * as i6 from "../../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i7 from "ngx-loading";
import * as i8 from "../../shared/query/query.component.ngfactory";
import * as i9 from "../../shared/query/query.component";
import * as i10 from "../../shared/query/input-group.directive";
import * as i11 from "./blocked-id-management.component";
import * as i12 from "@angular/router";
import * as i13 from "../../services/blocked-id-management.service";
import * as i14 from "ngx-toastr";
var styles_BlockedIdManagementComponent = [i0.styles];
var RenderType_BlockedIdManagementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlockedIdManagementComponent, data: {} });
export { RenderType_BlockedIdManagementComponent as RenderType_BlockedIdManagementComponent };
function View_BlockedIdManagementComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessages.FIELD_IS_REQUIRED; _ck(_v, 1, 0, currVal_0); }); }
function View_BlockedIdManagementComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "form-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enter User ID"])), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "input-group ps textBoxWidhtFix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [["fleetId", 1], ["userId", 1]], null, 7, "input", [["class", "form-control dropdownfix"], ["formControlName", "fleetId"], ["id", ""], ["placeholder", "Enter User ID"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keyup"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keyup" === en)) {
        var pd_4 = (_co.ValidateEmailFormat(i1.ɵnov(_v, 3).value) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(6, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(8, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i1.ɵdid(10, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "invalid-error"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlockedIdManagementComponent_2)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "form-control dropdownfix"; var currVal_8 = (!_co.status ? "is-invalid boxColor" : ""); _ck(_v, 5, 0, currVal_7, currVal_8); var currVal_9 = "fleetId"; _ck(_v, 8, 0, currVal_9); var currVal_10 = !_co.status; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 10).ngClassValid; var currVal_5 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_BlockedIdManagementComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "action-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeStatus() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.action; _ck(_v, 1, 0, currVal_0); }); }
function View_BlockedIdManagementComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-query-result", [], null, null, null, i4.View_QueryResultComponent_0, i4.RenderType_QueryResultComponent)), i1.ɵdid(1, 638976, null, 0, i5.QueryResultComponent, [], { paginationCounterVisbibe: [0, "paginationCounterVisbibe"], tableData: [1, "tableData"], totalRecords: [2, "totalRecords"], currentPage: [3, "currentPage"], columnData: [4, "columnData"], customCellTemplates: [5, "customCellTemplates"] }, null), i1.ɵpod(2, { "action": 0 }), (_l()(), i1.ɵand(0, [["dicButton", 2]], null, 0, null, View_BlockedIdManagementComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = _co.tabledata; var currVal_2 = 1; var currVal_3 = 1; var currVal_4 = _co.tableColumns; var currVal_5 = _ck(_v, 2, 0, i1.ɵnov(_v, 3)); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_BlockedIdManagementComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "lyt-main"], ["id", "lyt-main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "cp-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "ngx-loading", [], null, null, null, i6.View_NgxLoadingComponent_0, i6.RenderType_NgxLoadingComponent)), i1.ɵdid(3, 114688, null, 0, i7.NgxLoadingComponent, [i7.NgxLoadingService, i1.ChangeDetectorRef], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "app-query", [["title", "Manage Blocked Fleet Owners"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "query"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("query" === en)) {
        var pd_2 = (_co.fetchData() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_QueryComponent_0, i8.RenderType_QueryComponent)), i1.ɵdid(5, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(7, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), i1.ɵdid(8, 638976, null, 1, i9.QueryComponent, [], { title: [0, "title"], allowDownload: [1, "allowDownload"] }, { query: "query" }), i1.ɵqud(603979776, 1, { inputGroups: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_BlockedIdManagementComponent_1)), i1.ɵdid(11, 16384, [[1, 4]], 0, i10.InputGroupDirective, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlockedIdManagementComponent_3)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showLoading; _ck(_v, 3, 0, currVal_0); var currVal_8 = _co.ownerDataFormGroup; _ck(_v, 5, 0, currVal_8); var currVal_9 = "Manage Blocked Fleet Owners"; var currVal_10 = false; _ck(_v, 8, 0, currVal_9, currVal_10); var currVal_11 = _co.showTableData; _ck(_v, 13, 0, currVal_11); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 7).ngClassValid; var currVal_6 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_BlockedIdManagementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-blocked-id-management", [], null, null, null, View_BlockedIdManagementComponent_0, RenderType_BlockedIdManagementComponent)), i1.ɵdid(1, 114688, null, 0, i11.BlockedIdManagementComponent, [i12.Router, i3.FormBuilder, i13.BlockedIdManagementService, i14.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlockedIdManagementComponentNgFactory = i1.ɵccf("app-blocked-id-management", i11.BlockedIdManagementComponent, View_BlockedIdManagementComponent_Host_0, {}, {}, []);
export { BlockedIdManagementComponentNgFactory as BlockedIdManagementComponentNgFactory };
