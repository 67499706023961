<div class=tableFooter>
  <!-- <div class="paginationLeft">
    <div class="page-content">
        {{firstRecordNum}} to {{lastRecordNum}} of {{totalRecords}} Entries
      </div>
    </div> -->
  <div class="paginationRight">
    <div class="page-content">
      {{firstRecordNum}} to {{lastRecordNum}} of {{totalRecords}} Entries
    </div>
    <div class="nextArrow" [ngStyle]="{'display': isPresentGoToPrevious ? 'flex' : 'none'}"><span> <img [src]="LESS_THAN_ARROW_SVG">
    </span></div>

    <div class="previousPage" [ngStyle]="{'display': isPresentGoToPrevious ? 'flex' : 'none'}" (click)="goToPrevious()">
      Prev </div>

    <div [ngClass]="isActivePageOption1 ? 'pageActive' : 'pageInactive'"
      [ngStyle]="{'display': isPresentPageOption1 ? 'flex' : 'none'}" (click)="routeToOptionOne()">{{pageOption1}}
    </div>

    <div [ngClass]="isActivePageOption2 ? 'pageActive' : 'pageInactive'"
      [ngStyle]="{'display': isPresentPageOption2 ? 'flex' : 'none'}" (click)="routeToOptionTwo()">{{pageOption2}}
    </div>

    <div [ngClass]="isActivePageOption3 ? 'pageActive' : 'pageInactive'"
      [ngStyle]="{'display': isPresentPageOption3 ? 'flex' : 'none'}" (click)="routeToOptionThree()">{{pageOption3}}
    </div>

    <div class="nextPage" [ngStyle]="{'display': isPresentGoToNext ? 'flex' : 'none'}" (click)="goToNext()"> Next </div>
    <div class="nextArrow" [ngStyle]="{'display': isPresentGoToNext ? 'flex' : 'none'}"><span> <img [src]="GREATER_THAN_ARROW_SVG"></span>
    </div>
          <form class="pgft" [ngStyle]="{'display': totalRecords > 30 ? 'flex' : 'none'}">
            <input style="padding-left: 5px; margin-left: 10px;" type="number" name="page_index" #page_index placeholder= "Enter page no of total {{total_pages_cnt}}"/>
            <button class="nextPage" type="button"  (click)="jumpToPage(page_index.value)">Go to</button>
          </form>
    </div>
  </div>
  