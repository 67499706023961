import * as firebase from 'firebase/app';
import 'firebase/analytics';
import * as i0 from "@angular/core";
import * as i1 from "../config.service";
export class FirebaseService {
    constructor(configService) {
        this.configService = configService;
    }
    createEvent(...args) {
        firebase.analytics().logEvent(args[0], args[1]);
    }
}
FirebaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FirebaseService_Factory() { return new FirebaseService(i0.ɵɵinject(i1.ConfigService)); }, token: FirebaseService, providedIn: "root" });
