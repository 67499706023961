import { Component, OnInit, ViewChild } from '@angular/core';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';
import { checkPattern } from 'src/app/shared/configurations/pattern-constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { RouterModule, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { errorMessages } from 'src/app/shared/configurations/error-messages-constants';
import * as moment from 'moment';
import {
	HttpClient,
	HttpHeaders
} from '@angular/common/http';
import { appLevelConstants } from 'src/app/shared/configurations/app-level-constants';
import { AdminLogService } from 'src/app/services/admin-log.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver'
import { ASCENDING_SVG,ASCENDING_GREY_SVG ,DESCENDING_GREY_SVG ,DESCENDING_SVG } from '@shared/configurations/image-constants';

@Component({
	selector: 'app-admin-log',
	templateUrl: './admin-log.component.html',
	styleUrls: ['./admin-log.component.scss']
})
export class AdminLogComponent implements OnInit {
	ASCENDING_SVG = ASCENDING_SVG;
	ASCENDING_GREY_SVG = ASCENDING_GREY_SVG;
	DESCENDING_GREY_SVG = DESCENDING_GREY_SVG;
	DESCENDING_SVG = DESCENDING_SVG;
	reqObj: any;
	adminlogQuery: FormGroup;
	submitted: any;
	reqObj2: any = {
		from_datetime: "",
		page_number: 1,
		sort_by: "asc",
		to_datetime: "",
		fleetowner_email: "",
	};
	minDates: Date;

	waterMarkLabel = localStorage.getItem('user_id');
	type: any;
	displayMessage: string;
	vehiclePlaybackNote: string;
	totalRecords: any;
	result: any;
	checkPattern = checkPattern;
	public showLoading = false;
	showTableData: boolean = false;
	useridfield: boolean = localStorage.getItem('role_type') == "Admin" ? true : false;
	errorMessages = errorMessages;
	constructor(private router: Router,
		public spinnerService: NgxSpinnerService,
		private toaster: ToastrService,
		private formBuilder: FormBuilder,
		private http: HttpClient,
		private adminlogservice: AdminLogService) {
		var token = localStorage.getItem('auth_token');
		if (token == undefined || token === '') {
			this.router.navigate(['/login']);
		}
		this.minDates = new Date();
		this.minDates.setDate(this.minDates.getDate() - 30);
	}
	public selectedMoment = new Date();
	todayDate = moment();
	fromDate = moment().subtract(1, 'month');

	locale = {
		format: 'DD/MM/YYYY HH:mm:ss',
		firstDay: 1
	};

	globalData: any = [];
	mainresult_array = [];
	isAdminFormInValid = false;
	imageLink = "../../../../assets/images";
	selectedResponse: string;
	completeResponsePopupVisible: Boolean = false;
	vehicleListarray: [];
	adminLogData: [];
	watermarkLabel: string = localStorage.getItem('user_id');
	useridactivity: string = localStorage.getItem('user_id');
	dropdownList = [];
	selectedItems = [];
	dropdownSettings: IDropdownSettings;
	resultCheck: boolean = false;
	pageOfItems = [];
	editStartDate: string;
	editEndDate: string;
	start_date: string;
	end_date: string;
	pgnNo: string;
	searchData: string;
	public apps: any[] = [];
	vechileDetails: any;
	public min = new Date(2018, 1, 12, 10, 30);
	public yearMax = this.getCurrentDay('Y');
	public monthMax = this.getCurrentDay('M');
	public dateMax = this.getCurrentDay('D');
	private todate = new Date();
	public Hours = this.todate.getHours();
	public getMinutes = this.todate.getMinutes();
	public max = new Date(this.yearMax, this.monthMax, this.dateMax, 20, 30);
	public myDate: IMyDate = {
		year: this.getCurrentDay('Y'),
		month: this.getCurrentDay('M'),
		day: this.getCurrentDay('D'),
	}


	columns = ["Admin User ID", "API Name", 'API URL', 'Request Parameter', 'API Response', 'Created On'];
	heading = [
		{
			name: "Admin User ID",
			value: "user_name",
			isAscBlue: false,
			isDescBlue: false,
		},
		{
			name: "API Name",
			value: "api_name",
			isAscBlue: false,
			isDescBlue: false,
		},
		{
			name: 'API URL',
			value: 'api_url',
			isAscBlue: false,
			isDescBlue: false,
		},
		{
			name: 'Request Parameter',
			value: 'api_request',
			isAscBlue: false,
			isDescBlue: false,
		},
		{
			name: 'API Response',
			value: 'api_response',
			isAscBlue: false,
			isDescBlue: false,
		},
		{
			name: 'Created On',
			value: 'created_on',
			isAscBlue: false,
			isDescBlue: false,
		}
	];
	getCurrentDay(cat): any {
		let output;
		var today = new Date();
		var day = today.getDate();
		var month = today.getMonth() + 1; //January is 0!
		if (day != 1) {
		} else {
			if (month == 5 || month == 7 || month == 10 || month == 12) {
				day = 30;
			}
			if (month == 2 || month === 4 || month == 6 || month == 8 || month == 9 || month == 11 || month == 1) {
				day = 31;
			}
			if (year % 4 == 0 && month == 3) {
				day = 29;
			}
			else if (year % 4 != 0 && month == 3) {
				day = 28;
			}
			month = month - 1;
		}
		var year = today.getFullYear();
		if (cat === 'D') {
			output = day;
		}
		else if (cat === 'M') {
			output = month;
		}
		else {
			output = year;
		}
		return output;
	}
	public myDatePickerOptions: IMyDpOptions = {
		dateFormat: 'yyyy-mm-dd',
		markCurrentDay: true,
		disableSince: this.myDate
	};
	compareDate() {
		var startDate = new Date(this.editStartDate);
		var endDate = new Date(this.editEndDate);
		var seconds = (endDate.getTime() - startDate.getTime()) / 1000;
		if (endDate < startDate || seconds > 86400) {
			this.editStartDate = null;
			this.editEndDate = null;
			this.start_date = "";
		} else if (this.editEndDate != null && this.editStartDate != null) {
			this.editStartDate = this.convert(this.editStartDate);
			this.editEndDate = this.convert(this.editEndDate);
		}
	}
	convert(str) {
		var date = new Date(str),
			mnth = ("0" + (date.getMonth() + 1)).slice(-2),
			day = ("0" + date.getDate()).slice(-2);
		var Hours = date.getHours();
		var getMinutes = date.getMinutes();

		var returnDtae = [date.getFullYear(), mnth, day].join("-");
		returnDtae = returnDtae + "T" + Hours + ":" + getMinutes + ":00";
		return returnDtae;
	}
	onEditStartDateChanged(event) {
		var splitedDate = this.start_date.toString().split(",");
		this.editStartDate = splitedDate[0];
		this.editEndDate = splitedDate[1];
		if (this.editEndDate != null && this.editStartDate != null) {
			this.compareDate();
		}
	}

	get fc() {
		return this.adminlogQuery.controls;
	}
	get fv() {
		return this.adminlogQuery.value;
	}
	nextPage(page) {

		if (this.fc.adminId.value) {
			this.fetchReport(this.reqObj2.sort_by, page);
		}
	}

	ngOnInit() {
		this.adminlogQuery = this.formBuilder.group({
			adminId: ['', [Validators.email,]],
			apiName: ['', []],
			dateTime :['', []]

		});

		this.monthMax = this.monthMax - 1;
		this.max = new Date(this.yearMax, this.monthMax, this.dateMax, this.Hours, this.getMinutes);
		let d = new Date();
		var sevenDaysAgo = d.setDate(d.getDate() - 30);

	}

	timechangeFunction(any) {
		
		return new Date(any).toLocaleString();
	}
	fetchReport(sortBy: string, page_number) {
		this.checkAdminFormValidation(this.fc.adminId.value)
		this.showLoading = true;
		this.submitted = true;
		if (this.isAdminFormInValid) {
			this.showLoading = false;
			return;
		}
		else {
			let fromdate,todate = ''
			if (this.fc.dateTime.value.startDate==undefined){
				fromdate = ''
				todate = ''
			}
			else{
				fromdate  = this.fc.dateTime.value.startDate.toDate().toISOString().slice(0, 19)
				todate = this.fc.dateTime.value.endDate.toDate().toISOString().slice(0, 19)
			}

			this.submitted = true;
			this.reqObj2 = {
				'user_name': this.fc.adminId.value,
				'sort_by': sortBy,
				'page_number': page_number ? page_number : 1,
				'search_api_name': this.fc.apiName.value,
				'from_datetime': fromdate,
				'to_datetime': todate

			};
			this.adminlogservice.getAdminLog(this.reqObj2).subscribe((response: any) => {

				this.showLoading = false;

				if (response.status === 0 && response.hasOwnProperty("result") === true) {
					if (response.result.length <= 0) {
						this.showTableData = false;
						this.toaster.error("No record found", '', {
							timeOut: 5000
						});
					} else {
						this.adminLogData = response.result;
						this.totalRecords = response.total_count;
						this.showTableData = true;
						this.toaster.success(response.message, '', {
							timeOut: 5000
						});
					}
				} else {
					this.showTableData = false;
					this.toaster.error(response.message, '', {
						timeOut: 5000
					});
				}
			}, (error) => {
				this.showLoading = false;
				this.toaster.error(error.message, '', {
					timeOut: 5000
				});
			}
			)
		}
	}

	sort(field, type) {
		this.fetchReport(type, null)
	}

	getUpDownChange(colname, sort) {
		this.heading.forEach((element) => {
			if (element.value === colname) {
				if (sort === "asc") {
					element.isAscBlue = true;
					element.isDescBlue = false;
				} else {
					element.isDescBlue = true;
					element.isAscBlue = false;
				}
			} else {
				element.isAscBlue = false;
				element.isDescBlue = false;
			}
		});
	}


	viewCompleteResponse(data) {
		this.selectedResponse = data;
		this.completeResponsePopupVisible = !this.completeResponsePopupVisible;
	}


	numToAlpha(num: number) {
		let alpha = '';
		for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
			alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
		}
		return alpha;
	}

	async generateXl() {
		const workbook = new Workbook();
		const worksheet = workbook.addWorksheet("admin_log");
		worksheet.addRow([]);
		worksheet.mergeCells('A1:' + this.numToAlpha(this.columns.length - 1) + '1');
		worksheet.getCell('A1').value = appLevelConstants.TEXT_FOR_COPYRIGHT;
		worksheet.getCell('A1').alignment = { horizontal: 'center' };
		worksheet.getCell('A1').font = { bold: true };
		const headerRow = worksheet.addRow(this.columns).alignment = { wrapText: true };
		worksheet.columns = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }]
		let columnsArray: any[];
		for (const key in this.globalData) {
			if (this.globalData.hasOwnProperty(key)) {
				columnsArray = Object.keys(this.globalData[key]);
			}
		}
		this.globalData.forEach((element: any) => {
			const eachRow = [];
			columnsArray.forEach((column) => {
				eachRow.push(element[column]);
			});
			worksheet.addRow(eachRow).alignment = { wrapText: true };
		});
		workbook.xlsx.writeBuffer().then((data1: ArrayBuffer) => {
			const blob = new Blob([data1], { type: 'xlsx' });
			fs.saveAs(blob, "admin-log-" + this.fc.adminId.value + ".xlsx");
		});
		this.globalData = [];
	}

	downloadAsExcelFile() {
		this.checkAdminFormValidation(this.fc.adminId.value)
		this.showLoading = true;
		this.submitted = true;
		if (this.isAdminFormInValid) {
			this.showLoading = false;
			return;
		}
		let fromdate,todate = ''
		if (this.fc.dateTime.value.startDate==undefined){
			fromdate = ''
			todate = ''
		}
		else{
			fromdate  = this.fc.dateTime.value.startDate.toDate().toISOString().slice(0, 19)
			todate = this.fc.dateTime.value.endDate.toDate().toISOString().slice(0, 19)
		}
		let reqObj2 = {
			'user_name': this.fc.adminId.value,
			'sort_by': 'desc',
			'search_api_name': this.fc.apiName.value,
			'from_datetime': fromdate,
			'to_datetime': todate
		};

		this.adminlogservice.getAdminData(reqObj2).subscribe(
			(data: any) => {
				this.showLoading = false;
				if (data.hasOwnProperty("result")) {
					this.toaster.success("Data retrieved successfully", '', {
						timeOut: 5000
					});
					this.mainresult_array = data.result;
					let jsonObj2 = {};
					this.globalData = [];
					for (let i = 0; i < data.result.length; i++) {
						jsonObj2["Admin User ID"] = data.result[i].user_name;
						jsonObj2["API Name"] = data.result[i].api_name;
						jsonObj2["API URL"] = data.result[i].api_url;
						jsonObj2["Request Parameter"] = data.result[i].api_request;
						jsonObj2["API Response"] = data.result[i].api_response;
						jsonObj2["Created on"] = this.timechangeFunction(data.result[i].created_on);

						this.globalData.push(jsonObj2);
						jsonObj2 = {};
					}
					if (this.globalData.length > 0) {
						this.generateXl();
					}
					else {
						this.toaster.error(data.message, '', {
							timeOut: 5000
						});
					}
				}
				else {
					this.toaster.error(data.message, '', {
						timeOut: 5000
					});
				}
			}, (err) => {
				this.showLoading = false;
				var jsonObj = JSON.parse(JSON.stringify(err));
				var message = jsonObj.msg;
				if (message != undefined) {
					alert(message);
				}
				else {
					this.toaster.success("Please try again", '', {
						timeOut: 5000
					});
				}
			});
	}


	checkAdminFormValidation(value){
		if(this.checkPattern.EMAIL_VALIDATION.test(value)){
			this.isAdminFormInValid = false
		  }  
		  else{
			this.isAdminFormInValid = true
		  }
	}
}

