import * as i0 from "@angular/core";
import * as i1 from "../config.service";
export class RoleCheckGuard {
    constructor(configService) {
        this.configService = configService;
    }
    canActivate(next, state) {
        let permissionList = next.data.permission;
        let userInfo = this.configService.getLoggedInUserInfo();
        if (permissionList.includes(userInfo.role_type)) {
            return true;
        }
        else {
            console.log("permission allowed for ", permissionList, "permission found ", userInfo.role_type);
            return false;
        }
    }
    canActivateChild(next, state) {
        let permissionList = next.data.permission;
        let userInfo = this.configService.getLoggedInUserInfo();
        if (permissionList.includes(userInfo.role_type)) {
            return true;
        }
        else {
            console.log("permission allowed for ", permissionList, "permission found ", userInfo.role_type);
            return false;
        }
    }
}
RoleCheckGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleCheckGuard_Factory() { return new RoleCheckGuard(i0.ɵɵinject(i1.ConfigService)); }, token: RoleCheckGuard, providedIn: "root" });
