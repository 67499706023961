/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./query-result.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../table-pagination/table-pagination.component.ngfactory";
import * as i4 from "../table-pagination/table-pagination.component";
import * as i5 from "./query-result.component";
var styles_QueryResultComponent = [i0.styles];
var RenderType_QueryResultComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QueryResultComponent, data: {} });
export { RenderType_QueryResultComponent as RenderType_QueryResultComponent };
function View_QueryResultComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "th", [["class", "text-cap"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.displayName; _ck(_v, 1, 0, currVal_0); }); }
function View_QueryResultComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = (((_v.parent.context.$implicit.transform !== undefined) && (_v.parent.context.$implicit.transform !== null)) ? _v.parent.context.$implicit.transform(_v.parent.parent.context.$implicit[_v.parent.context.$implicit.dataKey]) : _v.parent.parent.context.$implicit[_v.parent.context.$implicit.dataKey]); _ck(_v, 1, 0, currVal_0); }); }
function View_QueryResultComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = (((_v.parent.context.$implicit.alternative !== undefined) && (_v.parent.context.$implicit.alternative !== null)) ? _v.parent.context.$implicit.alternative : "-"); _ck(_v, 1, 0, currVal_0); }); }
function View_QueryResultComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "td", [["class", "custom-cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 2, null, null, null, null, null, null, null)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { $implicit: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.parent.parent.context.$implicit); var currVal_1 = _co.customCellTemplates[_v.parent.context.$implicit.dataKey]; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_QueryResultComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryResultComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryResultComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryResultComponent_7)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.parent.context.$implicit[_v.context.$implicit.dataKey] !== null) && (_v.parent.context.$implicit[_v.context.$implicit.dataKey] !== undefined)) && !_v.context.$implicit.custom); _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_v.parent.context.$implicit[_v.context.$implicit.dataKey] === null) || (_v.parent.context.$implicit[_v.context.$implicit.dataKey] === undefined)) && !_v.context.$implicit.custom); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.custom && _co.customCellTemplates[_v.context.$implicit.dataKey]); _ck(_v, 6, 0, currVal_2); }, null); }
function View_QueryResultComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["- "]))], null, null); }
function View_QueryResultComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryResultComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, [["noData", 2]], null, 0, null, View_QueryResultComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columnData; _ck(_v, 2, 0, currVal_0); }, null); }
function View_QueryResultComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-pagination", [], null, [[null, "paginationEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("paginationEvent" === en)) {
        var pd_0 = (_co.onPaginationEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_TablePaginationComponent_0, i3.RenderType_TablePaginationComponent)), i1.ɵdid(1, 638976, null, 0, i4.TablePaginationComponent, [], { totalRecords: [0, "totalRecords"], currentPage: [1, "currentPage"] }, { paginationEvent: "paginationEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.totalRecords; var currVal_1 = _co.currentPage; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_QueryResultComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryResultComponent_10)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.totalRecords; _ck(_v, 2, 0, currVal_0); }, null); }
function View_QueryResultComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "sec-cont tb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "cp-card typ-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "mat-elevation-z4 table-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "table", [["class", "table table-bordered"], ["id", "excel-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryResultComponent_2)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryResultComponent_3)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryResultComponent_9)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columnData; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.tableData; _ck(_v, 11, 0, currVal_1); var currVal_2 = _co.paginationCounterVisbibe; _ck(_v, 13, 0, currVal_2); }, null); }
export function View_QueryResultComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_QueryResultComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.tableData && (_co.tableData.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_QueryResultComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-query-result", [], null, null, null, View_QueryResultComponent_0, RenderType_QueryResultComponent)), i1.ɵdid(1, 638976, null, 0, i5.QueryResultComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QueryResultComponentNgFactory = i1.ɵccf("app-query-result", i5.QueryResultComponent, View_QueryResultComponent_Host_0, { paginationCounterVisbibe: "paginationCounterVisbibe", watermarkEnabled: "watermarkEnabled", tableData: "tableData", totalRecords: "totalRecords", currentPage: "currentPage", columnData: "columnData", customCellTemplates: "customCellTemplates" }, { pageChange: "pageChange" }, []);
export { QueryResultComponentNgFactory as QueryResultComponentNgFactory };
