<div class="lyt-main" id="lyt-main">
    <div class="cp-section">
      <ngx-loading [show]="showLoading"></ngx-loading>
      <app-query title="Admin Login Requests" (query)="fetchReport('desc',1)" [allowDownload]="true" [formGroup]="adminlogQuery" (download)="downloadAsExcelFile()" >

        <ng-template InputGroup >
          <label for="" class="form-label" >Admin ID</label>
          <div class="input-group">
            <input type="text" [ngClass]="{'form-label-invalid':isAdminFormInValid}" 
            (keyup)="checkAdminFormValidation()" #adminid class="form-control font inputText"  placeholder="Email" autocomplete="off" formControlName="adminId"/>
          </div>
        </ng-template>

        <!-- <ng-template InputGroup >
          <label for="" class="form-label" >Filter by Request Status</label>
          <div class="form-group">
          <div class="input-group">           
            <p-dropdown  [options]="dropdownList" optionLabel="filterRequests" id="request-dropdown"
            formControlName="roles" class="form-control font inputText ">
          </p-dropdown>
        </div>
            </div>
            </ng-template> -->

            <ng-template InputGroup>
              <label for="" class="form-label"> Data Time Range </label>
              <div class="input-group ps">
                <img class="ngx-daterangepicker-action calendar-icon" src="../../../assets/images/calendar-icon.svg" alt="">
                <input type="text" placeholder="Select Date Time Range" class="form-input form-control mgleft"
                  [readonly]="true" ngxDaterangepickerMd [timePicker]="true" [timePickerIncrement]="1"
                  [timePicker24Hour]="true" [locale]="locale" formControlName="dateTime" [minDate]="fromDate"
                  [maxDate]="todayDate"
                  [ngClass]="(submitted || fc.dateTime.dirty) && fc.dateTime.errors ? 'is-invalid' : ''" />
                <div *ngIf="(submitted || fc.dateTime.dirty) && fc.dateTime.errors" class="invalid-error">
                  <div *ngIf="fc.dateTime.errors.required && submitted">
                    {{errorMessages.FIELD_IS_REQUIRED}}
                  </div>
                  <div *ngIf="fc.dateTime.errors.invalid">
                    {{errorMessages.INVALID_DATE}}
                  </div>
                  <div *ngIf="fc.dateTime.errors.incorrect">
                    {{errorMessages.DATA_OUT_OF_RANGE}}
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template InputGroup>
              <label for="" class="form-label">Filter by Request Status</label>
              <div class="input-group">
      
                <ng-multiselect-dropdown class="custom-font-size form-control" formControlName="requestStatus" [ngClass]="{'form-label-invalid':isStatusFilterEmpty}" 
                  [placeholder]="'Select Atleast 1 Option'" [(ngModel)]="selectedItems" [data]="dropdownList"
                  [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                  >
                </ng-multiselect-dropdown>
<!--       
                <div *ngIf="(submitted || fc.requestStatus.dirty) && fc.alertType.errors" class="invalid-error">
                  <div *ngIf="fc.alertType.errors.required && submitted">
                    {{errorMessages.FIELD_IS_REQUIRED}}
                  </div>
                </div> -->
      
              </div>
            </ng-template>

        </app-query>
        <div *ngIf="isAdminFormInValid" class="invalid-error">
          <div *ngIf="isAdminFormInValid">
            Enter Valid Data
          </div>
        </div>  

        <div *ngIf="showTableData">
          <p-table [columns]="heading" [value]="adminRequestData" [resizableColumns]="true" [autoLayout]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let header of heading" >
                        <span class="text">{{ header.name }}</span>
                        <span class="sort-icons" *ngIf="header.value === 'last_updated_at'">
                            <span class="ascendingIcon"
                                (click)="sort(header.value, 'asc'); getUpDownChange(header.value, 'asc')">
                                <span>
                                    <img [src]="ASCENDING_SVG"  *ngIf="header.isAscBlue" />
                                    <img [src]="ASCENDING_GREY_SVG" *ngIf="!header.isAscBlue" />
                                </span>
                        </span>
                        <span class="descendingIcon" (click)="sort(header.value, 'desc'); getUpDownChange(header.value, 'desc')">
                                <span>
                                    <img [src]="DESCENDING_SVG"  *ngIf="header.isDescBlue" />
                                    <img [src]="DESCENDING_GREY_SVG" *ngIf="!header.isDescBlue" />
                                </span>
                        </span>
                        </span>
                    </th>
                </tr>
            </ng-template>
            <ng-template #recordsBlock pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td class="text-grey mat-column">
                        {{ rowData.email_id }}
                    </td>
                    <td class="text-grey mat-column">
                      {{ rowData.ticket_no }}
                  </td>
                  <td class="text-grey mat-column">
                    {{ rowData.reason_for_creation }}
                </td>
                <td  class="text-grey mat-column">
                  
                    {{ rowData.request_status }}
                  
                  <!-- <div *ngIf="rowData.api_request.length>=20">
                    <a (click)="viewCompleteResponse(rowData.api_request)">View Request</a>
                  </div> -->
              </td>
    
              <td class="text-grey mat-column">
                {{ timechangeFunction(rowData.created_at) }}
            </td>
            <td class="text-grey mat-column">
              {{ timechangeFunction(rowData.last_updated_at) }}
          </td>
    
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length">
                        "No Data Found"
                    </td>
                </tr>
            </ng-template>
          </p-table>
          </div>

    
    
      <div class="pagination" >
        <app-table-pagination *ngIf="showTableData" (paginationEvent)="nextPage($event)"
            [totalRecords]='totalRecords' [currentPage]="reqObj2.page_number">
        </app-table-pagination>
    </div>
    

    </div>
</div>
