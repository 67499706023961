import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/app';
firebase.initializeApp(environment.firebaseConfig);
// import { UserDetailsComponent } from './Components/user-details/user-details.component';
const DEFAULT_SWIPER_CONFIG = {
    observer: true,
    direction: 'horizontal',
    slidesPerView: 1,
    a11y: true
};
const DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
const ɵ0 = DEFAULT_SWIPER_CONFIG, ɵ1 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
export class AppModule {
}
export { ɵ0, ɵ1 };
