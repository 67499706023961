/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./handle-short-links.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./handle-short-links.component";
import * as i3 from "@angular/router";
import * as i4 from "../../services/admin-log.service";
import * as i5 from "@angular/common";
var styles_HandleShortLinksComponent = [i0.styles];
var RenderType_HandleShortLinksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HandleShortLinksComponent, data: {} });
export { RenderType_HandleShortLinksComponent as RenderType_HandleShortLinksComponent };
export function View_HandleShortLinksComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_HandleShortLinksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-handle-short-links", [], null, null, null, View_HandleShortLinksComponent_0, RenderType_HandleShortLinksComponent)), i1.ɵdid(1, 114688, null, 0, i2.HandleShortLinksComponent, [i3.Router, i4.AdminLogService, i5.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HandleShortLinksComponentNgFactory = i1.ɵccf("app-handle-short-links", i2.HandleShortLinksComponent, View_HandleShortLinksComponent_Host_0, {}, {}, []);
export { HandleShortLinksComponentNgFactory as HandleShortLinksComponentNgFactory };
