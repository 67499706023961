<div class="lyt-main" id="lyt-main">
    <div class="cp-section">
        <ngx-loading [show]='showLoading'></ngx-loading>
      <app-query title="Manage Blocked Fleet Owners" (query)="fetchData()" [allowDownload]="false" [formGroup]="ownerDataFormGroup"
      >
        <ng-template class="form-group" InputGroup>
          <label class="form-label">Enter User ID</label>
          <div class="input-group ps textBoxWidhtFix">
            <input type="text" placeholder="Enter User ID" formControlName="fleetId"
            (keyup)="ValidateEmailFormat(fleetId.value)" #fleetId
            [ngClass]="!status? 'is-invalid boxColor': ''"
              id="" #userId class="form-control dropdownfix">
        </div>
        <div class="invalid-error">

          <span *ngIf="!status" class="text-danger">{{
              errorMessages.FIELD_IS_REQUIRED }}</span>
      </div>
      </ng-template>

      </app-query>
      <app-query-result *ngIf="showTableData" [columnData]="tableColumns" [tableData]="tabledata"
      [customCellTemplates]="{'action':dicButton}" [paginationCounterVisbibe]="false"
      [currentPage]="1" [totalRecords]="1">
            
      <ng-template let-rowData #dicButton>
        <p class="action-button" (click)="changeStatus()">{{rowData.action}}</p>
      </ng-template>

      </app-query-result>
  
  
  
    </div>
  </div>