import { Routes } from '@angular/router';
// import { PlanComponent } from './Components/plan/plan.component';
import { LoginComponent } from './Components/login/login.component';
import { ClientListComponent } from './Components/client-list/client-list.component';
import { ClientApiComponent } from './Components/client-api/client-api.component';
import { UserProfileComponent } from './Components/user-profile/user-profile.component';
import { AuthGuard } from './services/auth.guard';
import { appLevelConstants } from '@shared/configurations/app-level-constants';
import { RoleCheckGuard } from './services/role-check.guard';
import { AdminLogComponent } from './Components/admin-log/admin-log.component';
import { VehicleMasterCrnComponent } from './Components/vehicle-master-crn/vehicle-master-crn.component';
import { HandleShortLinksComponent } from './Components/handle-short-links/handle-short-links.component';
import { AdminRequestComponent } from './Components/admin-request/admin-request.component';
import { AdminAccessComponent } from './Components/admin-access/admin-access.component';
import { PushNotificationComponent } from './Components/push-notification/push-notification.component';
import { BlockedIdManagementComponent } from './Components/blocked-id-management/blocked-id-management.component';
import { VehicleOnBoardingComponent } from './Components/vehicle-on-boarding/vehicle-on-boarding/vehicle-on-boarding.component';
import { FuelTrendComponent } from './fuel-trend/fuel-trend.component';
const ɵ0 = { permission: ["Admin4"] }, ɵ1 = { permission: ["Admin5"] }, ɵ2 = { permission: ["Admin11"] }, ɵ3 = {
    permission: [
    // appLevelConstants.ROLES[0],
    // appLevelConstants.ROLES[1],
    ]
}, ɵ4 = {
    permission: [
        appLevelConstants.ROLES[0],
        appLevelConstants.ROLES[1],
        appLevelConstants.ROLES[2],
    ]
}, ɵ5 = {
    permission: [
        appLevelConstants.ROLES[0],
        appLevelConstants.ROLES[1],
        appLevelConstants.ROLES[2],
    ]
}, ɵ6 = {
    permission: [
    // appLevelConstants.ROLES[0],
    // appLevelConstants.ROLES[1],
    ]
}, ɵ7 = {
    permission: [
    // appLevelConstants.ROLES[0],
    // appLevelConstants.ROLES[1],
    ]
}, ɵ8 = {
    permission: [
    // appLevelConstants.ROLES[0],
    // appLevelConstants.ROLES[1],
    ]
}, ɵ9 = {
    permission: [
        appLevelConstants.ROLES[0],
        appLevelConstants.ROLES[1],
    ]
}, ɵ10 = {
    permission: [
        appLevelConstants.ROLES[0],
        appLevelConstants.ROLES[1],
        appLevelConstants.ROLES[2],
    ]
}, ɵ11 = () => import("./Components/admin/admin.module.ngfactory").then(m => m.AdminModuleNgFactory), ɵ12 = () => import("./Components/admin/admin.module.ngfactory").then(m => m.AdminModuleNgFactory), ɵ13 = () => import("./Components/user/user.module.ngfactory").then(m => m.UserModuleNgFactory), ɵ14 = () => import("./Components/events/events.module.ngfactory").then(m => m.EventsModuleNgFactory), ɵ15 = () => import("./Components/vehicle-data/vehicleData.module.ngfactory").then(m => m.VehicleDataModuleNgFactory), ɵ16 = () => import("./Components/client-data/clientData.module.ngfactory").then(m => m.ClientDataModuleNgFactory), ɵ17 = () => import("./Components/post-subscription/post-subscription.module.ngfactory").then(m => m.PostSubscriptionModuleNgFactory);
const routes = [
    // NO PERMISSION ROUTES =============================
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'ref_lnk',
        component: HandleShortLinksComponent,
    },
    // REROUTE===========================================
    {
        path: 'eventdata',
        redirectTo: "events/eventdata",
        canActivate: [AuthGuard]
    },
    {
        path: 'fuel-alerts',
        redirectTo: "events/fuel-alerts",
        canActivate: [AuthGuard]
    },
    {
        path: 'vehicle',
        redirectTo: 'clientData/vehicle',
        canActivate: [AuthGuard]
    },
    {
        path: 'vehiclecandata',
        redirectTo: 'vehicleData/vehiclecandata',
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'vehiclefuel',
    //     redirectTo: 'vehicleData/vehiclefuel',
    //     canActivate: [AuthGuard]
    // },
    {
        path: 'ignition',
        redirectTo: 'vehicleData/ignition',
        canActivate: [AuthGuard]
    },
    {
        path: 'telemetrydata',
        redirectTo: "vehicleData/telemetrydata",
        canActivate: [AuthGuard]
    },
    {
        path: 'onboardUser',
        redirectTo: "user/onboardUser",
        canActivate: [AuthGuard]
    },
    {
        path: 'userActivity',
        redirectTo: "user/userActivity",
        canActivate: [AuthGuard]
    },
    {
        path: 'user-access-control',
        redirectTo: "admin/useraccesscontrol",
        canActivate: [AuthGuard]
    },
    // COMPONENTS===========================================
    {
        path: 'client',
        component: ClientListComponent,
        canActivate: [AuthGuard],
        data: ɵ0
    },
    {
        path: 'clientapi',
        component: ClientApiComponent,
        canActivate: [AuthGuard],
        data: ɵ1
    },
    {
        path: 'userProfile',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
        data: ɵ2
    },
    {
        path: "admin-log",
        component: AdminLogComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: ɵ3
    },
    {
        path: "vehicle-master-crm",
        component: VehicleMasterCrnComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: ɵ4
    },
    {
        path: "fuel-trend",
        component: FuelTrendComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: ɵ5
    },
    {
        path: "vehicle-on-board",
        component: VehicleOnBoardingComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: ɵ6
    },
    {
        path: "admin-request",
        component: AdminRequestComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: ɵ7
    },
    {
        path: "admin-access",
        component: AdminAccessComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: ɵ8
    },
    {
        path: "push-notification",
        component: PushNotificationComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: ɵ9
    },
    {
        path: "blocked-id-management",
        component: BlockedIdManagementComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: ɵ10
    },
    // COLLECTION OF COMPONENT IMPORT AS CHILD=================================================
    {
        path: "admin",
        loadChildren: ɵ11,
        canActivate: [AuthGuard]
    },
    {
        path: "user",
        loadChildren: ɵ12,
        canActivate: [AuthGuard]
    },
    {
        path: "user",
        loadChildren: ɵ13,
        canActivate: [AuthGuard]
    },
    {
        path: "events",
        loadChildren: ɵ14,
        canActivate: [AuthGuard]
    },
    {
        path: "vehicleData",
        loadChildren: ɵ15,
        canActivate: [AuthGuard]
    },
    {
        path: "clientData",
        loadChildren: ɵ16,
        canActivate: [AuthGuard]
    },
    {
        path: "subscription",
        loadChildren: ɵ17,
        canActivate: [AuthGuard]
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17 };
