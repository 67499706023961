<div class="typ-form">
    <div class="lyt-form">
        <form [formGroup]="pushNotificationForm">
            <div class="input-container form-container">
                <div class="sec-head typ-prev set-margin">
                    <h2 class="sec-title title-css label-css">{{ title }}</h2>
                </div>
                <div class="sec-cont">
                    <div class="cp-form typ-shadow">
                        <div class="form-head typ-flex">
                            <h3 class="title label-css heading-color">
                                {{ pushNotificationDetailLabel }}
                            </h3>
                        </div>
                        <div class="form-cont">
                            <ul class="field-list row list-3">
                                <li class="field-item col-item ">
                                    <div class="form-group radio-list-item">
                                        <label class="form-label">
                                            {{noticationAsPer}}
                                        </label>
                                        <div class="radio-container">
                                            <div class="form-check-inline">
                                                <input class="form-check-input radio-btn" type="radio"
                                                    name="isNotificationType" [value]="true"
                                                    [(ngModel)]="isNotificationType"
                                                    formControlName="isNotificationType"
                                                    (change)="onNotificatioTypeChange()" />
                                                <label class="form-check-label">
                                                    {{notificationUserType}}
                                                </label>
                                            </div>
                                            <div class="form-check-inline">
                                                <input class="form-check-input radio-btn" type="radio"
                                                    name="isNotificationType" [value]="false"
                                                    [(ngModel)]="isNotificationType"
                                                    formControlName="isNotificationType"
                                                    (change)="onNotificatioTypeChange()" />
                                                <label class="form-check-label">{{ notificationSelectiveUser }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div>
                                <ul class="field-list row" *ngIf="isNotificationType">
                                    <li class="field-item col-item item-2">
                                        <div class="form-group">
                                            <label class="form-label">{{ userTypeLabel }}</label>
                                            <div class="input-group">
                                                <p-dropdown class="usertype-dropdown-cls" [options]="userTypes"
                                                    placeholder="Select User Types" formControlName="usertype"
                                                    optionLabel="userType" optionValue="userType" id="usertype-dropdown"
                                                    #usertype>
                                                </p-dropdown>
                                                <div *ngIf="(submitted || getFormControls.usertype.dirty) &&getFormControls.usertype.errors"
                                                    class="invalid-error">
                                                    <span *ngIf="getFormControls.usertype.errors.required"
                                                        class="text-danger">{{ errormessages.REQUIRED}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="field-list row " *ngIf="!isNotificationType">
                                    <li class="field-item col-item item-1">
                                        <div class="form-group" *ngIf="!showbulkicon">
                                            <div class="right-wrap download-btn class-padding class-display "
                                                (click)="bulkUpload()">

                                                <div class="item">
                                                    <button class="btn btn-icon-text">
                                                        <span class="icon icon-upload"></span> Bulk
                                                        Upload</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="file-select-ui" *ngIf="showbulkicon">
                                            <div class="file-selected">
                                                <div class="first-class">
                                                    <div class="img-class">
                                                        <img [src]="EXCEL_SVG" width="50"
                                                            class="bulk-icon-home">
                                                    </div>
                                                    <div class="size-class">{{fileSize}}</div>
                                                </div>
                                                <div class="name-date-class">
                                                    <div class="name-class" title="filename"> {{filename}}</div>
                                                    <!-- <div class="date-class">
                                                        {{lastModified}}
                                                    </div> -->
                                                </div>
                                                <div class="third-class">
                                                    <button class="btn btn-icon" (click)="showbulkicon=false">
                                                        <span
                                                            class="ui-button-icon-left ui-clickable pi pi-times"></span>
                                                        <span class="text class-remove">
                                                            {{HTML_DATA_VARIABLES.remove}}
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="field-list row ">
                                    <li class="field-item col-item item-1">
                                        <div class="form-group">
                                            <label class="form-label"> {{ notificationTitleLabel }} </label>
                                            <div class="input-group">
                                                <input #titleName type="text" class="form-control font inputText"
                                                    placeholder="Enter Title Here" formControlName="titleName"
                                                    autocomplete="off" />
                                                <div *ngIf="(submitted || getFormControls.titleName.dirty) && getFormControls.titleName.errors"
                                                    class="invalid-error">
                                                    <span *ngIf="getFormControls.titleName.errors.required"
                                                        class="text-danger">{{
                                                        errormessages.REQUIRED }}<br /></span>
                                                    <span *ngIf="getFormControls.titleName.errors.minlength"
                                                        class="text-danger">{{
                                                        errormessages.ATLEAST_3_CHARACTERS }}<br /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="field-list row ">
                                    <li class="field-item col-item item-1">
                                        <div class="form-group">
                                            <label class="form-label"> {{ notificationBodyLabel }} </label>
                                            <div class="input-group">
                                                <textarea #bodyName class="input-textarea"
                                                    style="resize:none;height: 15rem;" cols="50"
                                                    class="text-area form-control font inputText "
                                                    placeholder="Enter Body Here" formControlName="bodyName"
                                                    autocomplete="off"></textarea>
                                                <div *ngIf="(submitted || getFormControls.bodyName.dirty) && getFormControls.bodyName.errors"
                                                    style="margin-top: 115px;" class="invalid-error ">
                                                    <span *ngIf="getFormControls.bodyName.errors.required"
                                                        class="text-danger">{{
                                                        errormessages.REQUIRED }}<br /></span>

                                                    <span *ngIf="getFormControls.bodyName.errors.minlength"
                                                        class="text-danger">{{
                                                        errormessages.ATLEAST_3_CHARACTERS }}<br /></span>

                                                </div>
                                            </div>
                                        </div>
                                        <h5 class="note">{{notificationBodyNote}}</h5>
                                    </li>
                                </ul>
                                <ul class="field-list row ">
                                    <li class="field-item col-item item-1">
                                        <div class="form-group">
                                            <label class="form-label"> {{ notificationTypeLabel }} </label>
                                            <div class="checkbox-container">
                                                <div class="checkbox-inline"
                                                    *ngFor="let notificationType of notificationTypes; let i=index">
                                                    <input #selectNotificationType type="checkbox"
                                                        class="form-control font inputText checkbox-input-box"
                                                        formControlName="selectNotificationType"
                                                        [value]="notificationType.value"
                                                        (change)="onCheckboxChange($event)" />
                                                    <label class="checkbox-label">

                                                        {{ notificationType.name }}
                                                    </label>
                                                </div>
                                                <div *ngIf="(submitted || getFormControls.selectNotificationType.dirty ) && getFormControls.selectNotificationType.errors"
                                                    style="left: 2px; margin-top: 0px;" class="invalid-error">
                                                    <span *ngIf="getFormControls.selectNotificationType.errors.required"
                                                        class="text-danger">{{
                                                        errormessages.REQUIRED }}<br /></span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="bulk-upload-popup">
                    <div class="cp-modal typ-upload width-class">
                        <p-dialog [(visible)]="uploadDisplay" [transitionOptions]="'400ms'" [dismissableMask]="true"
                            [modal]="true" [draggable]="false" [responsive]="true" [baseZIndex]="10000"
                            [closable]="true">
                            <p-header>
                                <div class="modal-header-class">
                                    <div>
                                        <div>
                                            <h3 class="title">Bulk Upload Corridor</h3>
                                            <button class="link-class"></button>
                                        </div>
                                        <div>
                                            <button class="link-class">
                                                <a class="download-img" href="{{templateLink}}" download>
                                                    {{HTML_DATA_VARIABLES.download}}
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </p-header>
                            <div class="modal-body class-modal-body class-body popup_size">
                                <div class="lyt-form">
                                    <form action="">
                                        <div class="cp-form upload-doc-wrap class-set-upload">
                                            <div class="main-block">
                                                <div class="file-upload">
                                                    <div class="file-upload-status typ-iconText" *ngIf="!fileInput">
                                                        <div class="bulk-icon">
                                                            <p-fileupload accept=".xlsx, csv, .xls" chooselabel=" "
                                                                maxfilesize="10485760" url="./upload.php"
                                                                ng-reflect-url="./upload.php"
                                                                ng-reflect-accept=".xlsx, csv, .xls"
                                                                ng-reflect-auto="true"
                                                                ng-reflect-max-file-size="10485760"
                                                                ng-reflect-choose-label=" ">
                                                                <div class="ui-fileupload ui-widget ng-star-inserted"
                                                                    ng-reflect-ng-class="ui-fileupload ui-widget">
                                                                    <div
                                                                        class="ui-fileupload-buttonbar ui-widget-header ui-corner-top">
                                                                        <span
                                                                            class="ui-fileupload-choose ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-left"
                                                                            icon="pi pi-plus" pbutton=""
                                                                            ng-reflect-klass="ui-fileupload-choose"
                                                                            ng-reflect-ng-class="[object Object]"
                                                                            ng-reflect-label=" "
                                                                            ng-reflect-icon="pi pi-plus">
                                                                            <form [formGroup]="uploadFile"
                                                                                #bulkUploadForm class="form-class">

                                                                                <input #myInput type="file"
                                                                                    (change)="uploadedFile($event)"
                                                                                    placeholder="File Name"
                                                                                    name="filename" title=" "
                                                                                    accept=".xlsx,.xlsm, csv, .xls"
                                                                                    formControlName="fileInput"
                                                                                    [ngClass]="{ 'is-invalid': (submitted || f.fileInput.dirty) && f.fileInput.errors }">

                                                                            </form>
                                                                            <span aria-hidden="true"
                                                                                class="ui-button-icon-left ui-clickable pi pi-plus">

                                                                            </span>
                                                                            <span class="ui-button-text ui-clickable">

                                                                            </span>
                                                                        </span>

                                                                    </div>

                                                                    <div class="ui-fileupload-content ui-widget-content ui-corner-bottom class-box"
                                                                        ng-reflect-ng-class="[object Object]">

                                                                        <p-messages class="ng-tns-c10-1"
                                                                            ng-reflect-enable-service="false">
                                                                        </p-messages>

                                                                    </div>
                                                                </div>
                                                            </p-fileupload>

                                                        </div>
                                                        <!-- </button> -->
                                                        <div class="attach-file-class">
                                                            <span class="class-attach-file">
                                                                Attach File
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="file-error">
                                                    <div *ngIf="(submittedexcel || f.fileInput.dirty) && f.fileInput.errors"
                                                        class="invalid-error">
                                                        <div *ngIf="f.fileInput.errors.required" class="text-danger">
                                                            {{errormessages.FILE_IS_REQUIRED}}
                                                        </div>
                                                    </div>

                                                    <div class="sec-head failed-sheet" *ngIf="isDisabled">
                                                        <div class="failed-sheet-text-file" *ngIf="isFailedSheet">

                                                            <div class="right-wrap download-btn class-padding">
                                                                <button class="btn-icon btn download-btn class-color">
                                                                    <span class="icon icon-download">
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div class="failed-sheet-text">
                                                                Failed excel sheet
                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>
                                                <div class="sec-head failed-sheet" *ngIf="isDisabled">
                                                    <div class="failed-sheet-text-file" *ngIf="isFailedSheet">

                                                        <div class="right-wrap download-btn class-padding">
                                                            <button class="btn-icon btn download-btn class-color">
                                                                <span class="icon icon-download">
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div class="failed-sheet-text">
                                                            Failed excel sheet
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="file-select-ui" *ngIf="!isDisabled">
                                                    <div class="file-selected"
                                                        *ngIf="fileUploaded && !uploadFile.controls.fileInput.errors && uploadFile.controls.fileInput.value != ''">
                                                        <div class="first-class">
                                                            <div class="img-class">
                                                                <img [src]="GROUP_6" width="50"
                                                                    class="">
                                                            </div>
                                                            <div class="size-class">{{fileSize}}</div>
                                                        </div>
                                                        <div class="name-date-class">
                                                            <div class="name-class" title="filename"> {{filename}}</div>
                                                            <!-- <div class="date-class">
                                                                {{lastModified}}
                                                            </div> -->
                                                        </div>
                                                        <div class="third-class">
                                                            <button class="btn btn-icon" (click)="clearForm()">
                                                                <span
                                                                    class="ui-button-icon-left ui-clickable pi pi-times"></span>
                                                                <span class="text class-remove">
                                                                    {{HTML_DATA_VARIABLES.remove}}
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="failed-sheet-text" *ngIf="isCorrectSheet">
                                                    {{HTML_DATA_VARIABLES.blanckSheetError}}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="act-wrap class-submit footer-save-btn">
                                        <div>
                                            <button class="btn btn-outline"
                                                (click)=" cancelBulkUploadForm()">Cancel</button>
                                        </div>
                                        <div>
                                            <button class="btn btn-default add-btn upload-btn"
                                                (click)="ReadAsJson()">Upload</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-dialog>
                    </div>
                </div>
                <div class="footer-save-btn">
                    <div class="form-btn-wrap">
                        <button class="btn btn-outline" type="button" (click)="clickCancel()">
                            {{ cancelButton }}
                        </button>

                        <button [disabled]="(selectNotificationTypeArrayEmpty || !showbulkicon)"
                            class="btn btn-default add-btn" (click)="onSubmit()">
                            {{ submitButton }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <ngx-loading [show]="showLoading"></ngx-loading>
</div>