<header>
  <!-- <div class="cp-modal typ-confirm">
    <p-dialog [(visible)]="showPopUp" [transitionOptions]="'400ms'" [dismissableMask]="true" [modal]="true"
      [draggable]="false" [responsive]="true" [baseZIndex]="10000">
      <p-header>
        <div class="modal-header">
          <h3 class="title typ-center" id="logout-title">Are you sure you want to Logout?</h3>
        </div>
      </p-header>
      <div class="modal-body">
        <div class="act-wrap">
          <button class="btn btn-default" (click)="logOut()">Yes</button>
          <button class="btn btn-outline" (click)="showPopUp = false">No</button>
        </div>
      </div>
    </p-dialog>
  </div> -->
  <div class="cp-modal typ-confirm">
    <p-dialog [(visible)]="showPopUp" [transitionOptions]="'400ms'" [dismissableMask]="true" [modal]="true"
      [draggable]="false" [responsive]="true" [baseZIndex]="10000">
      <p-header>
        <div class="modal-header">
          <h3 class="title typ-center" id="logout-title">Are you sure you want to Logout?</h3>
        </div>
      </p-header>
      <div class="modal-body">
        <div class="act-wrap">
          <button class="btn btn-default" (click)="logOut()">Yes</button>
          <button class="btn btn-outline" (click)="showPopUp = false">No</button>
        </div>
      </div>
    </p-dialog>
  </div>

  <div class="cp-modal typ-confirm" *ngIf="isSessionExpired">
    <p-dialog [(visible)]="isSessionExpired" [transitionOptions]="'300ms'" [dismissableMask]="true" [modal]="true"
      [closable]="false" [draggable]="false" [responsive]="true" [baseZIndex]="10000">
      <p-header>
        <div class="modal-header">
          <h3 class="title typ-center title-css">Session Expired</h3>
        </div>
      </p-header>
      <div class="modal-body">
        <span class="popup-content">Your session is expired. Please login to continue.</span>
        <div class="form-btn-wrap kyc-popup">
          <button class="btn btn-outline" (click)="logOut()">OK</button>
        </div>
      </div>
    </p-dialog>
  </div>
</header>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport="false"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">
    <mat-toolbar>
      <a mat-list-item>
        <!-- <img src="assets/images/tm-logo.svg" alt="" /> -->
        <img [src]="TATA_LOGO_BLACK" class="tata_logo" alt="" />
        <img [src]="BANNER_LOGO" alt="" class="tata_text_logo" />
      </a>
    </mat-toolbar>
    <mat-nav-list class="clrs">
      <ul class="nav-list" id="icon-size">
        <li *ngIf="isUserRoleAdmin || isTMLInternal || isSupportDeskUser" mat-list-item routerLinkActive="active"
          class="nav-item">
          <a [routerLink]="['/subscription/report']" class="nav-link">
            <span class="icon icon-size icon-checklist"></span>
            <span class="text">Subscription</span>
          </a>
        </li>
        <!-- <li *ngIf="isUserRoleAdmin || isTMLInternal || isSupportDeskUser" mat-list-item routerLinkActive="active"
          class="nav-item">
          <a [routerLink]="['/subscription/sensorize-status']" class="nav-link">
            <span class="icon icon-size icon-truck"></span>
            <span class="text">{{sensorize}}</span>
          </a>
        </li> -->
        <li mat-list-item routerLinkActive="active" class="nav-item" *ngIf="isUserRoleAdmin">
          <a [routerLink]="['/user/onboardUser']" class="nav-link">
            <span class="icon icon-size icon-add"></span>
            <span class="text">{{ userOnboardLabel }}</span>
          </a>
        </li>
        <li mat-list-item routerLinkActive="active" class="nav-item "
          *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser ">
          <a [routerLink]="['/events/eventdata']" class="nav-link">
            <span class="icon icon-size icon-notifcation-o"></span>
            <span class="text">{{ eventDataLabel }}</span>
          </a>
        </li>
        <li mat-list-item routerLinkActive="active" class="nav-item "
          *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser">
          <a [routerLink]="['/events/fuel-alerts']" class="nav-link">
            <span class="icon icon-size icon-fuel"></span>
            <span class="text">{{ fuelAlertLabel }}</span>
          </a>
        </li>
        <li mat-list-item routerLinkActive="active" class="nav-item "
          *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser || isElectra">
          <a class="nav-link" [routerLink]="['/vehicleData/telemetrydata']">
            <span class="icon icon-size icon-track"></span>
            <span class="text">{{ telemetryDataLabel }}</span>
          </a>
        </li>
        <li *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser" mat-list-item routerLinkActive="active"
          class="nav-item">
          <a [routerLink]="['/user/vehicle-event']" class="nav-link">
            <span class="icon icon-size icon-checklist"></span>
            <span class="text">Vehicle Events - Detailed View</span>
          </a>
        </li>
        <li mat-list-item routerLinkActive="active" class="nav-item "
          *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser || isElectra">
          <a class="nav-link " [routerLink]="['/vehicleData/vehiclecandata']">
            <span class="icon icon-size icon-truck"></span>
            <span class="text">{{ vehicleCanDataLabel }}</span>
          </a>
        </li>
        <!-- <li mat-list-item routerLinkActive="active" class="nav-item "
          *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser">
          <a [routerLink]="['/vehicleData/vehiclefuel']" class="nav-link">
            <span class="icon icon-size icon-odometer"></span>
            <span class="text">{{ fuelAndOdometerDataLabel }}</span></a>
        </li> -->
        <li mat-list-item routerLinkActive="active" class="nav-item"
          *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser ||isAccolade">
          <a [routerLink]="['/vehicleData/ignition']" class="nav-link">
            <span class="icon icon-size icon-truck"></span>
            <span class="text">{{ ignitionStatusLabel }}</span>
          </a>
        </li>
        <li mat-list-item routerLinkActive="active" class="nav-item"
          *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser">
          <a [routerLink]="['/clientData/vehicle']" class="nav-link">
            <span class="icon icon-size icon-information"></span>
            <span class="text">Vehicle List</span>
          </a>
        </li>
        <li mat-list-item routerLinkActive="active" class="nav-item"
          *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser">
          <a [routerLink]="['/blocked-id-management']" class="nav-link">
            <span class="icon icon-size icon-information"></span>
            <span class="text">Manage Blocked Fleet Owners</span>
          </a>
        </li>
        <li mat-list-item routerLinkActive="active" class="nav-item"
          *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser">
          <a [routerLink]="['/user/userActivity']" class="nav-link">
            <span class="icon icon-size icon-eye"></span>
            <span class="text">User Activity</span>
          </a>
        </li>
        <li mat-list-item routerLinkActive="active" class="nav-item"
          *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser">
          <a [routerLink]="['/vehicle-master-crm']" class="nav-link">
            <span class="icon icon-size icon-eye"></span>
            <span class="text">Vehicle List - CRM</span>
          </a>
        </li>
        <li mat-list-item routerLinkActive="active" class="nav-item"
          *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser">
          <a [routerLink]="['/fuel-trend']" class="nav-link">
            <span class="icon icon-size icon-eye"></span>
            <span class="text">Fuel Trend</span>
          </a>
        </li>
        <!-- <li mat-list-item routerLinkActive="active" class="nav-item " *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser || isElectra">
          <a class="nav-link " [routerLink]="['/vehicle-on-board']">
            <span class="icon icon-size icon-truck"></span>
            <span class="text">{{ vehicleOnBoardLabel }}</span>
          </a>
        </li>
        <li *ngIf="isUserRoleAdmin|| isTMLInternal" mat-list-item routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/admin-log']" class="nav-link">
            <span class="icon icon-size icon-eye"></span>
            <span class="text">Admin Logs</span>
          </a>
        </li>

        <li *ngIf="isUserRoleAdmin" mat-list-item routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/admin-request']" class="nav-link">
            <span class="icon icon-size icon-information"></span>
            <span class="text">Admin Requests</span>
          </a>
        </li> -->

        <li *ngIf="isUserRoleAdmin|| isTMLInternal" mat-list-item routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/push-notification']" class="nav-link">
            <span class="icon icon-size icon-notifcation-o"></span>
            <span class="text">Push Notification</span>
          </a>
        </li>

        <!--<li *ngIf="isUserRoleAdmin|| isTMLInternal" mat-list-item routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/admin-access']" class="nav-link">
            <span class="icon icon-size icon-checklist"></span>
            <span class="text">Edit Admin Access</span>
          </a>
        </li>
      

        <li *ngIf="isUserRoleAdmin|| isTMLInternal" mat-list-item routerLinkActive="active" class="nav-item">
          <a [routerLink]="['/admin/sms-registration']" class="nav-link">
            <span class="icon icon-size icon-notifcation-o"></span>
            <span class="text">Register SMS Template</span>
          </a>
        </li>
        -->
        <li *ngIf="isUserRoleAdmin|| isTMLInternal || isSupportDeskUser" mat-list-item routerLinkActive="active"
          class="nav-item">
          <a [routerLink]="['/admin/vin-enable-fuel-alert']" class="nav-link">
            <span class="icon icon-size icon-checklist"></span>
            <span class="text">Enable Fuel alert for VIN</span>
          </a>
        </li>

      </ul>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="top-nav">
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <!-- content add by vikas -->
      <p class="blank1"></p>
      <p class="blank2">
        <span>
          Internal Service Portal
        </span>
      </p>
      <!-- <span class="spacer"> </span> -->
      <!-- imp-->
      <div class="right-control-holder">
        <a mat-list-item>
          <span class="user-pic" [routerLink]="['/userProfile']"> 
            <img class="user-image" [src]="USER_PLACEHODER"/>
          </span>
        </a>
        <a mat-list-item>
          <span [routerLink]="['/userProfile']">{{ userInfo.full_name | titlecase }}</span>
        </a>
        <a mat-list-item>
          <span class="logout-btn" (click)="openLogoutPopup()" opened="!opened">
            <span class="icon icon-logout"></span>
          </span>
        </a>
      </div>
    </mat-toolbar>
  </mat-sidenav-content>
</mat-sidenav-container>