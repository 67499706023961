<div class="lyt-main" id="lyt-main">
    <div class="cp-section ">
        <ngx-loading [show]= false></ngx-loading>
        <app-query title="Fuel Trend" (query)="fetchData(false)" [allowDownload]= false
            [formGroup]="vehicleFuelTrend" [disclaimer]="errorMessages.DISCLAIMER_15DAYS">

            <div class="mid-section">
                <ng-template InputGroup>
                    <label for="" class="form-label">{{vinLabel}}</label>
                    <div class="input-group">
                        <input #vinNumber [ngClass]="(submitted || fc.vinNumber.dirty) && fc.vinNumber.errors? 'is-invalid'
                       : ''" class="form-input form-control" type="text" class="" placeholder="VIN Number" maxlength="17"
                            formControlName="vinNumber" autocomplete="off" />
                        <div *ngIf="(submitted || fc.vinNumber.dirty) && fc.vinNumber.errors" class="invalid-error">
                            <div *ngIf="(submitted || fc.vinNumber.dirty) && fc.vinNumber.errors.required">
                                {{errorMessages.FIELD_IS_REQUIRED}}
                            </div>
                            <div *ngIf="(submitted || fc.vinNumber.dirty)  && fc.vinNumber.errors.pattern ">
                                {{errorMessages.VIN_VALID}}
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template InputGroup>
                    <label for="" class="form-label">{{dateLabel}}</label>
                    <div class="input-group">
                        <img class="ngx-daterangepicker-action calendar-icon"
                            src="../../../assets/images/calendar-icon.svg" alt="">
                        <input type="text" placeholder="Select Date Time Range" class="form-input form-control"
                            [readonly]="true" ngxDaterangepickerMd [timePicker]="true" [timePickerIncrement]="1"
                            [timePicker24Hour]="true" [locale]="locale" formControlName="dateTime" [maxDate]="todayDate"
                            [ngClass]="(submitted || fc.dateTime.dirty) && fc.dateTime.errors ? 'is-invalid' : ''" />

                        <div *ngIf="(submitted || fc.dateTime.dirty) && fc.dateTime.errors" class="invalid-error">
                            <div *ngIf="(fc.dateTime.errors.required) && submitted">
                                {{errorMessages.FIELD_IS_REQUIRED}}
                            </div>
                            <div *ngIf="fc.dateTime.errors.invalid">
                                {{errorMessages.INVALID_DATE}}
                            </div>
                            <div *ngIf="fc.dateTime.errors.incorrect">
                                {{errorMessages.INCORRECT_DATE_TIME_15DAYS}}
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </app-query>


        <div class="chart">
            <apx-chart *ngIf="fuelArray && checkChart" [series]="fuelChartOptions.series"
                [chart]="fuelChartOptions.chart" [xaxis]="fuelChartOptions.xaxis" [stroke]="fuelChartOptions.stroke"
                [dataLabels]="fuelChartOptions.dataLabels" [yaxis]="fuelChartOptions.yaxis"
                [labels]="fuelChartOptions.labels" [legend]="fuelChartOptions.legend" [title]="fuelChartOptions.title"
                [tooltip]="fuelChartOptions.tooltip" [fill]="fuelChartOptions.fill" [colors]="fuelChartOptions.colors"
                [responsive]="fuelChartOptions.responsive">
            </apx-chart>
            <div *ngIf="!checkChart && this.showGraphData " class="loading">
                {{displayChartMessage}}
            </div>
        </div>
    </div>
</div>