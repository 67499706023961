import { HttpHeaders } from '@angular/common/http';
import { urlConstants } from 'src/app/shared/configurations/url-constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/common";
import * as i3 from "../config.service";
export class VehicleCanDataService {
    constructor(http, titlecasePipe, configService) {
        this.http = http;
        this.titlecasePipe = titlecasePipe;
        this.configService = configService;
        this.httpOptions = this.configService.getApplicationHeaders();
        this.addhttpOptions = this.configService.getAddEditHeaders();
    }
    vehicleCanDataApi(userData) {
        return this.http.post(urlConstants.VEHICLE_HISTORICAL_CAN_DATA, JSON.stringify(userData), this.getAddEditHeaders());
    }
    getVehicleStatus(data) {
        return this.http.post(urlConstants.VEHICLE_STATUS, JSON.stringify(data), this.getAddEditHeaders());
    }
    getKeyCloakToken() {
        return 'Bearer ' + localStorage.getItem('auth_token');
    }
    getAddEditHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-type': 'application/json',
                Authorization: this.getKeyCloakToken()
            })
        };
    }
    getVehichleVinsByCrm(data) {
        return this.http.post(urlConstants.GET_VEHICLE_VINS_BY_CRM, JSON.stringify(data), this.getAddEditHeaders());
    }
    addVehicleInVehicleMaster(data) {
        return this.http.post(urlConstants.ADD_VEHICLE_MASTER, JSON.stringify(data), this.getAddEditHeaders());
    }
    bulkUploadVehicleMaster(data) {
        return this.http.post(urlConstants.BULK_VEHICLE_MASTER, JSON.stringify(data), this.getAddEditHeaders());
    }
    keyOldNew(newObject, oldKey, newKey) {
        delete Object.assign(newObject, {
            [newKey]: this.titlecasePipe.transform(newObject[oldKey])
        })[oldKey];
        return newObject;
    }
    deleteKeys(newObject, keyName) {
        delete newObject[keyName];
        return newObject;
    }
    checkVehicleValidation(data) {
        return this.http.post(urlConstants.CHECK_VEHICLE_VALIDATION, JSON.stringify(data), this.getAddEditHeaders());
    }
    getUserId(data) {
        return this.http.post(urlConstants.GET_USER_ID, JSON.stringify(data), this.getAddEditHeaders());
    }
    getBranchId(data) {
        return this.http.post(urlConstants.GET_BRANCH, JSON.stringify(data), this.getAddEditHeaders());
    }
    getVehicleLob(data) {
        return this.http.post(urlConstants.GET_LOB_DROPDOWN, JSON.stringify(data), this.getAddEditHeaders());
    }
    getVehicleFuelTrend(data) {
        return this.http.post(urlConstants.GET_VEHICLE_FUEL_TREND, JSON.stringify(data), this.getAddEditHeaders());
    }
}
VehicleCanDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VehicleCanDataService_Factory() { return new VehicleCanDataService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TitleCasePipe), i0.ɵɵinject(i3.ConfigService)); }, token: VehicleCanDataService, providedIn: "root" });
