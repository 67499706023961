<div class="sec-head full-width-lw ">
    <div class="left-wrap">
        <h2 class="sec-title">{{title}}</h2>
    </div>

</div>

<div class="sec-head full-width-lw">
    <div class="right-wrap ">
        <ul class="row typ-multiline">
            

            <li class="act-item typ-full" *ngFor="let inputGroup of inputGroups ">
                <div class="cp-form">
                    
                    <div class="form-group">
                        <ng-container [ngTemplateOutlet]="inputGroup.templateRef" class="form-group">

                        </ng-container>
                    </div>

                </div>
            </li>

            <div class="search-download-container">
                <li class="act-button">
                    <button class="btn btn-icon-text" (click)="submit('query')">
                        <span class="icon icon-search"></span>
                    </button>
                </li>
                <li class="act-button">
                    <button *ngIf="allowDownload" class="btn btn-icon-text" (click)="submit('download')"><span
                            class="icon icon-download"></span></button>
                </li>
                <li class="act-button">
                    <button *ngIf="allowRefresh" class="btn btn-icon-text" (click)="submit('refresh')">
                        <mat-icon>refresh</mat-icon>

                    </button>
                </li>
            </div>
            <br>
            <div class="disclaimer" *ngIf="disclaimer">
                {{disclaimer}}
            </div>
        </ul>
    </div>
</div>