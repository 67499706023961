<div class="lyt-main" id="lyt-main">
    <div class="cp-section">
      <ngx-loading [show]="showLoading"></ngx-loading>
      <app-query title="Admin Logs" (query)="fetchReport('asc',1)" [allowDownload]="true" [formGroup]="adminlogQuery" (download)="downloadAsExcelFile()" >
  
  
        <ng-template InputGroup >
          <label for="" class="form-label" >Admin ID</label>
          <div class="input-group">
            <input type="text" [ngClass]="{'form-label-invalid':isAdminFormInValid}" 
            (keyup)="checkAdminFormValidation(adminid.value)" #adminid class="form-control font inputText"  placeholder="Email" autocomplete="off" formControlName="adminId"/>
          </div>
        </ng-template>

        <ng-template InputGroup>
          <label for="" class="form-label"> Data Time Range </label>
          <div class="input-group ps">
            <img class="ngx-daterangepicker-action calendar-icon" src="../../../assets/images/calendar-icon.svg" alt="">
            <input type="text" placeholder="Select Date Time Range" class="form-input form-control mgleft"
              [readonly]="true" ngxDaterangepickerMd [timePicker]="true" [timePickerIncrement]="1"
              [timePicker24Hour]="true" [locale]="locale" formControlName="dateTime" [minDate]="fromDate"
              [maxDate]="todayDate"
              [ngClass]="(submitted || fc.dateTime.dirty) && fc.dateTime.errors ? 'is-invalid' : ''" />
            <div *ngIf="(submitted || fc.dateTime.dirty) && fc.dateTime.errors" class="invalid-error">
              <div *ngIf="fc.dateTime.errors.required && submitted">
                {{errorMessages.FIELD_IS_REQUIRED}}
              </div>
              <div *ngIf="fc.dateTime.errors.invalid">
                {{errorMessages.INVALID_DATE}}
              </div>
              <div *ngIf="fc.dateTime.errors.incorrect">
                {{errorMessages.DATA_OUT_OF_RANGE}}
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template InputGroup>
          <label for="" class="form-label">API Name</label>
          <div class="input-group">
            <input type="text" class="form-control font inputText" placeholder="API Name" autocomplete="off" formControlName="apiName"/>
          </div>
        </ng-template>

      </app-query>
      
      <div *ngIf="isAdminFormInValid" class="invalid-error">
        <div *ngIf="isAdminFormInValid">
          Enter Valid Admin ID
        </div>
      </div>
      

      <div class="cp-modal">
      <p-dialog [(visible)]="completeResponsePopupVisible" [transitionOptions]="'400ms'" [dismissableMask]="true" [modal]="true" [draggable]="false" [responsive]="true" [baseZIndex]="11002">
        <p-header>
            <div class="modal-header">
                <h3 class="title typ-center">Request Parameter</h3>
            </div>
        </p-header>
        <div class="terms-and-conditions">
            {{ selectedResponse }}
        </div>
    </p-dialog>
  </div>
      <div *ngIf="showTableData">
      <p-table [columns]="heading" [value]="adminLogData" [resizableColumns]="true" [autoLayout]="true">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let header of heading" >
                    <span class="text">{{ header.name }}</span>
                    <span class="sort-icons" *ngIf="header.value === 'created_on'">
                        <span class="ascendingIcon"
                            (click)="sort(header.value, 'asc'); getUpDownChange(header.value, 'asc')">
                            <span>
                                <img [src]="ASCENDING_SVG"  *ngIf="header.isAscBlue" />
                                <img [src]="ASCENDING_GREY_SVG" *ngIf="!header.isAscBlue" />
                            </span>
                    </span>
                    <span class="descendingIcon" (click)="sort(header.value, 'desc'); getUpDownChange(header.value, 'desc')">
                            <span>
                                <img [src]="DESCENDING_SVG"  *ngIf="header.isDescBlue" />
                                <img [src]="DESCENDING_GREY_SVG" *ngIf="!header.isDescBlue" />
                            </span>
                    </span>
                    </span>
                </th>
            </tr>
        </ng-template>
        <ng-template #recordsBlock pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td class="text-grey mat-column">
                    {{ rowData.user_name }}
                </td>
                <td class="text-grey mat-column">
                  {{ rowData.api_name }}
              </td>
              <td class="text-grey mat-column">
                {{ rowData.api_url }}
            </td>
            <td  class="text-grey mat-column">
              <div *ngIf="rowData.api_request.length<20">
                {{ rowData.api_request }}
              </div>
              <div *ngIf="rowData.api_request.length>=20">
                <a (click)="viewCompleteResponse(rowData.api_request)">View Request</a>
              </div>
          </td>

          <td class="text-grey mat-column">
            {{ rowData.api_response }}
        </td>
        <td class="text-grey mat-column">
          {{ timechangeFunction(rowData.created_on) }}
      </td>

            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td [attr.colspan]="columns.length">
                    "No Data Found"
                </td>
            </tr>
        </ng-template>
      </p-table>
      </div>
    </div>
  </div>


  <div class="pagination" >
    <app-table-pagination *ngIf="showTableData" (paginationEvent)="nextPage($event)"
        [totalRecords]='totalRecords' [currentPage]="reqObj2.page_number">
    </app-table-pagination>
</div>
